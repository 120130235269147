import logo from '../logo.svg';
import Header from '../parts/header';
import Footer from '../parts/footer';
import {Helmet} from "react-helmet";

function AboutUs() {
  return (

    <div className="home">
       <Helmet>
           <meta charSet="utf-8" />
            <title>About Us -  PDFDom</title>
             <meta name="description" content="Learn about our dedication to quick and seamless PDF Conversion to various formats. Discover our commitment to your satisfaction!" />

            <link rel="canonical" href="https://www.pdfdom.com/" />
            </Helmet>
      <Header/>

      <Header/>



          <Footer/>

    </div>

  );
}

export default AboutUs;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
//import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider, ChakraBaseProvider,  cookieStorageManagerSSR,
  localStorageManager, useColorMode, Button, ColorModeScript } from '@chakra-ui/react'



import Header from './parts/header';
import Footer from './parts/footer';

import Home from './pages/home';
import SignUp from './pages/signup';
import Login from './pages/login';
import Dashboard from './pages/dashboard';
import TermsOfService from './pages/terms-of-service';
import PrivacyPolicy from './pages/privacy-policy';
import AboutUs from './pages/about-us';
import ContactUs from './pages/contact-us';
import MyAccount from './pages/my-account';
import Admin from './pages/admin';
import Reset from './pages/reset-password';
import theme from './theme'

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(  
  
  <ChakraProvider theme={theme}>


	   <App/>
    </ChakraProvider>

  ,

);
 function App({ cookies }) {

  const colorModeManager =
    typeof cookies === 'string'
      ? cookieStorageManagerSSR(cookies)
      : localStorageManager
  const { colorMode, toggleColorMode } = useColorMode()


  // 2. Pass `colorModeManager` prop - it finds the relevant cookie on its own
  return (

  <BrowserRouter>

      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />

      <Route path="/home" element={<Home />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/sign-in" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/my-account" element={<MyAccount />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="/reset-password" element={<Reset />} />
      <Route path="/pdf-to-word" element={<Dashboard />} />

      </Routes>


  </BrowserRouter>

  )
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

import logo from '../logo.svg';
import Header from '../parts/header';
import Footer from '../parts/footer';
import TestimonialWithSpeechBubbles from './home-parts/testimonials';
import Pricing from './home-parts/pricing';
import SignUpForm from './signup-parts/signupform';
import {Helmet} from "react-helmet";

function SignUp() {
  return (

    <div className="home">
          <Helmet>
           <meta charSet="utf-8" />
            <title>Create Account -  PDFDom</title>
             <meta name="description" content="Sign up for effortless pdf conversions" />

            <link rel="canonical" href="https://www.pdfdom.com/sign-up" />
            </Helmet>
      <Header/>

   		<SignUpForm/>

          <Footer/>

    </div>

  );
}

export default SignUp;

import {PriceWrapper} from '../home-parts/pricing'
import { FaCheckCircle } from 'react-icons/fa'
import { useToast } from '@chakra-ui/react'
import { Heading } from '@chakra-ui/react'
import { Center, Square, Circle } from '@chakra-ui/react'
import { Container, Stack, Code, Box,Text, HStack,VStack,ListItem, ListIcon, useColorModeValue, List, RadioGroup,Radio, Badge } from '@chakra-ui/react'
import {
	Input,
	FormControl,
	FormLabel,
	FormErrorMessage,
	FormHelperText,
} from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Button, ButtonGroup } from '@chakra-ui/react'

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
//import CheckoutForm from "./checkoutform";
import { CardElement, useElements, useStripe , PaymentElement, PaymentRequestButtonElement} from "@stripe/react-stripe-js";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'

import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";

//const stripePromise = loadStripe("pk_test_xTxkUI4xEvwcy5g79VXZa83G00ozIR3PBp"); // starts with pk_
 const stripePromise = loadStripe("pk_live_xVwah1y9DU0urQpzPPGvLbiM00Pdsq1Ri4"); // starts with pk_


function Plans(Props) {
	  const toast = useToast()

	console.log("all props are here");
	console.log(Props);
	  const stripe = useStripe();
  const elements = useElements();

    const [ selectedpaymetMethod, setSelectedPaymentMethod ] = useState("new");

	  const [ is3DSecureOpen, setis3DSecureOpen ] = useState(false);
    const { on3DSecureClose } = useState(false);
    const [ iframeSrc, setIframeSrc ] = useState("");
    const [ clientSecretIntent, setClientSecretIntent ] = useState("");
    const [ paymentIntentId, setPaymentIntentId ] = useState("");
        const [ pendingSubscriptionId, setPendingSubscriptionId ] = useState("");
        const [ selectedPlanId, setSelectedPlanId ] = useState("");
        const [ FocusedPlan, setFocusedPlan ] = useState("");

    const [ currentsubscriptionId, setcurrentSubscriptionId ] = useState("");
      useEffect(() => {

   		window.addEventListener('message', watch_for_results, false);
 return () => window.removeEventListener('message', watch_for_results);
  }, [Props, selectedPlanId, pendingSubscriptionId]);

const  watch_for_results =  (ev) => {
    if (ev.data.type === "stripe-3ds-result") {

      			if(ev.data.message.payment_intent.status == "succeeded")
      			{
                          console.log(ev)

      				 setis3DSecureOpen(false);
            setPaymentIntentId(ev.data.message.payment_intent.id);

            updateStripeSubscriptionId();

              toast({
          title: 'Payment has been succesfully processed.',
          description: "",
          status: 'success',
          duration: 3000,
          isClosable: true,
        })

                              ReactGA.event({
  category: "subscription",
  action: "created",
  amount:ev.data.message.payment_intent.amount/100,
  label: Props?.profileData?.id, // optional
  nonInteraction: true, // optional, true/false
});


      			}
     		



    }
  }




useEffect(function(){

  if(Props?.profileData?.saved_card_data?.id)
  {
    console.log(Props?.profileData?.saved_card_data?.id);
    setSelectedPaymentMethod(Props?.profileData?.saved_card_data?.id);
  }


}, [Props])




const updateStripeSubscriptionId = () =>
{
	console.log("update data");
	console.log({
          stripe_subscription_id: pendingSubscriptionId,
          id:Props?.profileData?.id,
          plan:selectedPlanId
        });
	 fetch("https://api.pdfdom.com/update-stripe-subscription-id", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          stripe_subscription_id: pendingSubscriptionId,
          id:Props?.profileData?.id,
          plan:selectedPlanId
        }),
      })
    .then(function(serverPromise){ 
     return  serverPromise.json()
        .then(function(data) { 
               console.log(data);
  
   



        })
        .catch(function(e){
          console.log(e);
        });
    })
    .catch(function(e){
        console.log(e);
      });
}      

const cancelSubscription = async (id) => {
    try {
fetch("https://api.pdfdom.com/cancel-subscription", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          plan:id,
          id:Props?.profileData?.id
        }),
      })
    .then(function(serverPromise){ 
     return  serverPromise.json()
        .then(function(data) { 
               console.log(data);
         if(data.stripe_subscription_end_date)
            { 
                     toast({
          title: 'Subscription has been cancelled! Your plan will expire on '+new Date(data.stripe_subscription_end_date* 1000).toLocaleDateString("en-US"),
          description: "",
          status: 'success',
          duration: 3000,
          isClosable: true,
        })

                     ReactGA.event({
  category: "subscription",
  action: "cancelled",
  label: Props?.profileData?.id, // optional
  nonInteraction: true, // optional, true/false
});
            }
            if(data.new_return)
            {
              
          }



        })
        .catch(function(e){
          console.log(e);
        });
    })
    .catch(function(e){
        console.log(e);
      });




    }
    catch (error) {
      console.log(error);
    }
  }

  const reactivateSubscription = async (id) => {
    try {
fetch("https://api.pdfdom.com/reactivate-subscription", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          plan:id,
          id:Props?.profileData?.id
        }),
      })
    .then(function(serverPromise){ 
     return  serverPromise.json()
        .then(function(data) { 
               console.log(data);
         if(data.subscriptionId)
            { 
                     toast({
          title: 'Subscription has been reactivated',
          description: "",
          status: 'success',
          duration: 3000,
          isClosable: true,
        })

                                 ReactGA.event({
  category: "subscription",
  action: "reactivated",
  label: Props?.profileData?.id, // optional
  nonInteraction: true, // optional, true/false
});
            }
           



        })
        .catch(function(e){
          console.log(e);
        });
    })
    .catch(function(e){
        console.log(e);
      });




    }
    catch (error) {
      console.log(error);
    }
  }
const createSubscription = async (id) => {
    try {
      if(selectedpaymetMethod == "new")
      {
      var payment_method_id = "";
      // create a payment method
      /*const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements?.getElement(CardElement),
        billing_details: {
          name:Props?.profileData?.first_name+" "+Props?.profileData?.last_name,
          email: Props?.profileData?.email
        },
      });*/
         const {error: submitError} = await elements.submit();
  if (submitError) {
    console.log(submitError);
    return;
  }

      const paymentMethod = await stripe?.createPaymentMethod({
        elements,
         params: {
        billing_details: {
          name:Props?.profileData?.first_name+" "+Props?.profileData?.last_name,
          email: Props?.profileData?.email
        }
      },
      });
      payment_method_id = paymentMethod?.paymentMethod?.id;
    }
    else{
      payment_method_id = selectedpaymetMethod;
    }
    console.log(payment_method_id);
      setSelectedPlanId(id);

      // call the backend to create subscription

     

 fetch("https://api.pdfdom.com/create-subscription", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          paymentMethod:payment_method_id,
          name:Props?.profileData?.first_name+" "+Props?.profileData?.last_name,
          email:Props?.profileData?.email,
          plan:id,
          id:Props?.profileData?.id
        }),
      })
    .then(function(serverPromise){ 
     return  serverPromise.json()
        .then(function(data) { 
               console.log(data);
         if(data.subscriptionId)
            { 
              setcurrentSubscriptionId(data.subscriptionId);
                setClientSecretIntent(data.clientSecret);
            const confirmPayment =  stripe?.confirmPayment(
            {
              clientSecret:data.clientSecret,
              confirmParams: {
    // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
    return_url: 'https://example.com',
  }
            }
            
            );

            console.log(confirmPayment);

            if (confirmPayment?.error) {

                toast({
          title: confirmPayment.error.message,
          description: "",
          status: 'error',
          duration: 3000,
          isClosable: true,
        })

            } else {

                console.log("all_the_payment_data");
console.log(data);

       


                toast({
          title: 'Payment has been succesfully processed.',
          description: "",
          status: 'success',
          duration: 3000,
          isClosable: true,
        })

                        ReactGA.event({
  category: "subscription",
  action: "created",
  amount:data.amount/100,
  label: Props?.profileData?.id, // optional
  nonInteraction: true, // optional, true/false
});

            }

            }
            if(data.new_return)
            {
              setClientSecretIntent(data.payment_intent.client_secret)
            if(data.new_return.use_stripe_sdk.type == "three_d_secure_redirect")
            {
            	console.log("data we have")
            	console.log(data);
            	setPendingSubscriptionId(data.stripe_subscription_id);
            console.log(data.new_return.use_stripe_sdk.stripe_js);
            setIframeSrc(data.new_return.use_stripe_sdk.stripe_js)
            setis3DSecureOpen(true);

            }
          }



        })
        .catch(function(e){
          console.log(e);
        });
    })
    .catch(function(e){
        console.log(e);
      });

   


     

    } catch (error) {
      console.log(error);
    }
  };




	const activatePlan = (id) => {

		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({  id:id})

		};
		console.log(requestOptions);
		fetch('https://api.pdfdom.com/api/user/profile', requestOptions)
		.then(function(response) {
			return response.json();
		}).then(function(data) {
			console.log(data);  



			if(data.id)
			{

			//	setProfileData(data);


			}


		});



	}


	return (
    <div>

     <Box w="80%" pt={7}>
     <Text fontWeight="500" fontSize="2xl">
      Payment Method
      </Text>
       <div style={Props?.profileData?.saved_card_data ? {} : {display:'None'}}>
         

  <RadioGroup value={selectedpaymetMethod} onChange={(e)=> {setSelectedPaymentMethod(e)} }>
  <Stack >
    <Radio colorScheme='red' value={Props?.profileData?.saved_card_data?.id} style={Props?.profileData?.saved_card_data?.id ? {}:{display:'None'}}>
      <Text style={Props?.profileData?.saved_card_data?.id ? {}:{display:'None'}}> XXXX-XXXX-XXXX-{Props?.profileData.saved_card_data?.card?.last4}</Text>
    </Radio>
    <Radio colorScheme='green' value='new'>
     Add New Card
    </Radio>
  </Stack>
</RadioGroup>


       </div>
      </Box>



      <Box style={{padding:'20px'}}w="80%" pt={7} style={selectedpaymetMethod == "new" ? {} : {display:'None'}}>
        
          <PriceWrapper>

  
              

        <PaymentElement />

        </PriceWrapper>

      </Box>
		<Stack
		direction={{ base: 'column', md: 'row' }}
		textAlign="center"
		justify="center"
		spacing={{ base: 4, lg: 10 }}
		py={10}>
		 <div>

    </div>

		{Props.plans.map(plan => {

     //  const color_mode =  useColorModeValue('gray.50', 'gray.700')
     return (

     	<PriceWrapper onClick={()=>setFocusedPlan(plan._id), Props.setPackageToParrent(plan.price_including_tax)}>
     	<Box py={4} px={12}>
     	<Text fontWeight="500" fontSize="2xl">
     	{plan.name}
     	</Text>
     	<HStack justifyContent="center">
     	<Text fontSize="3xl" fontWeight="600">
     	$
     	</Text>
     	<Text fontSize="5xl" fontWeight="900">
     	{plan.pricing}
     	</Text>
       
     	<Text fontSize="3xl" color="gray.500">
     	/{plan.billing_cycle == 'yearly' ? 'year': plan.billing_cycle == 'monthly' ? 'month': plan.billing_cycle == 'weekly' ? 'week': ''}
     	</Text>
     	</HStack>
       <Text fontSize="1xl" fontWeight="600">
      $ {plan.price_including_tax} (includes $ {plan.tax} tax)
      </Text>
     	</Box>
     	<VStack
     	bg={'gray.50'}
      color={'gray.500'}

     	py={4}
     	borderBottomRadius={'xl'}>
     	<List spacing={3} textAlign="start" px={12}>
     	<ListItem>
     	<ListIcon as={FaCheckCircle} color="green.500" />
     	Unlimited Photos
     	</ListItem>
     	<ListItem>
     	<ListIcon as={FaCheckCircle} color="green.500" />
     	API Access.
     	</ListItem>
     	<ListItem>
     	<ListIcon as={FaCheckCircle} color="green.500" />
     	Access to WordPress Plugin.
     	</ListItem>
     	</List>
       
       
     
     	<Box w="80%" pt={7}>
     	<Button w="full" colorScheme="red" style={Props?.profileData?.plan == plan._id ? {display:'None'}:{}} variant="outline" onClick={()=>activatePlan(plan._id)} onClick={()=>createSubscription(plan._id)} disabled={!stripe}>
     	{plan.has_trial ? 'Start '+plan.trial_days+' Free Trial': 'Start Today'}
     	</Button>

   <Button w="full" colorScheme="red" onClick={()=>cancelSubscription(plan._id)} variant="outline" style={Props?.profileData?.plan != plan._id ?  {display:'None'}: Props?.profileData?.stripe_subscription_end_date ? {display:'None'}: {} } disabled={!stripe}>
      {'Cancel Subscription'}
      </Button>
{Props?.profileData?.plan == plan._id && 
  <Badge colorScheme='red' style={Props?.profileData?.stripe_subscription_end_date ? {'marginBottom':'10px'}: {display:'None'}}>Plan Expiring at {new Date(Props?.profileData?.stripe_subscription_end_date* 1000).toLocaleDateString("en-US")}</Badge>}

        
   <Button w="full" colorScheme="red" onClick={()=>reactivateSubscription(plan._id)} variant="outline" style={Props?.profileData?.plan != plan._id ?  {display:'None'}: Props?.profileData?.stripe_subscription_end_date ? {}: {display:'None'} } disabled={!stripe}>
      {'Reactivate Subscription'}
      </Button>
      
     	</Box>
     	</VStack>
     	</PriceWrapper>
     	);
 })}
    

		<Modal isOpen={is3DSecureOpen} onClose={()=>setis3DSecureOpen(false)}>
        <ModalOverlay />
        <ModalContent>
        <iframe src={iframeSrc} />
         
        </ModalContent>
      </Modal>
		</Stack>
    </div>
		);
	}

	export default Plans;
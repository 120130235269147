import { Button, Input } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
function InvoicesForm(Props) {

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#dd4444",
        margin: '10px 0 20px 0',
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };
  
  // collect data from the user
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [priceId, setPriceId] = useState("");
    const [invoiceData, setInvoiceData] = useState([]);

 


  useEffect(() => {
    getInvoices(Props.UserId);


  }, []);


const getInvoices = (id) => {

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({  id:id})

    };
    console.log(requestOptions);
    fetch('https://api.pdfdom.com/api/user/invoices', requestOptions)
        .then(function(response) {
    return response.json();
  }).then(function(data) {
              console.log(data);  


 
   
     
        setInvoiceData(data);


    


  });



    }



 

  return (
    <div className="grid gap-4 m-auto">
    

     



<TableContainer>
  <Table variant='simple'>
    <TableCaption>Your Invoices</TableCaption>
    <Thead>
      <Tr>
        <Th>Invoice ID</Th>
        <Th>DATE</Th>
        <Th>Amount</Th>
        <Th>PDF</Th>

      </Tr>
    </Thead>
    <Tbody>
     {( invoiceData.length > 0) && invoiceData.map(function(item, i){

     	var invoicedatetime = new Date(item.status_transitions.paid_at* 1000).toLocaleDateString("en-US")
  return (<Tr>
        <Td>{item.number}</Td>
        <Td>{invoicedatetime}</Td>
        <Td>${item.amount_paid/100}</Td>

        <Td><a href={item.invoice_pdf}>Download</a></Td>
      </Tr>);
})}
      
    </Tbody>
    
  </Table>
</TableContainer>

     

 






    </div>
  );
}

export default InvoicesForm;
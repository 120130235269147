'use client'

import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
} from '@chakra-ui/react'
import { FaCheckCircle } from 'react-icons/fa'

interface Props {
  children: React.ReactNode
}

export function PriceWrapper(props: Props) {
  const { children } = props

  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: 'center', lg: 'flex-start' }}
      borderColor={useColorModeValue('gray.200', 'gray.500')}
      borderRadius={'xl'}>
      {children}
    </Box>
  )
}

export default function Pricing(Props) {
  return (
    <Box py={12}>
      <VStack spacing={2} textAlign="center">
        <Heading as="h1" fontSize="4xl">
          Enjoy Premium Features for Free
        </Heading>
        <Text fontSize="lg" color={'gray.500'}>
          Start with 14-day free trial.
        </Text>
      </VStack>
      <Stack
    direction={{ base: 'column', md: 'row' }}
    textAlign="center"
    justify="center"
    spacing={{ base: 4, lg: 10 }}
    py={10}>
     <div>

    </div>

    {Props.plans.map(plan => {

     // const color_mode =  useColorModeValue('gray.50', 'gray.700')
     return (

      <PriceWrapper>
      <Box py={4} px={12}>
      <Text fontWeight="500" fontSize="2xl">
      {plan.name}
      </Text>
      <HStack justifyContent="center">
      <Text fontSize="3xl" fontWeight="600">
      $
      </Text>
      <Text fontSize="5xl" fontWeight="900">
      {plan.pricing}
      </Text>
       
      <Text fontSize="3xl" color="gray.500">
      /{plan.billing_cycle == 'yearly' ? 'year': plan.billing_cycle == 'monthly' ? 'month': plan.billing_cycle == 'weekly' ? 'week': ''}
      </Text>
      </HStack>
    
      </Box>
      <VStack
      bg={'gray.50'}
      py={4}
      color={'gray.500'}
      borderBottomRadius={'xl'}>
      <List spacing={3} textAlign="start" px={12}>
      <ListItem>
      <ListIcon as={FaCheckCircle} color="green.500" />
      Unlimited Conversions
      </ListItem>
      <ListItem>
      <ListIcon as={FaCheckCircle} color="green.500" />
      No Ads.
      </ListItem>
      <ListItem>
      <ListIcon as={FaCheckCircle} color="green.500" />
      14 Day Free Trial.
      </ListItem>
      </List>
       
       
     
      <Box w="80%" pt={7}>
      <Button w="full" colorScheme="red" style={Props?.profileData?.plan == plan._id ? {display:'None'}:{}} variant="outline" onClick={()=> window.location.href = "sign-up"} >
      {plan.has_trial ? 'Start '+plan.trial_days+' Free Trial': 'Start Today' }
      </Button>

  


        

      
      </Box>
      </VStack>
      </PriceWrapper>
      );
 })}
      </Stack>
    </Box>
  )
}
import { CardElement, useElements, useStripe , PaymentElement, PaymentRequestButtonElement} from "@stripe/react-stripe-js";
import ReactGA from "react-ga4";
import { useToast } from '@chakra-ui/react'
import {useMemo, useState, useEffect} from 'react';
import {Text, Center, Square, Tabs, TabList, TabPanel, TabPanels, Tab, Circle, SimpleGrid, Box, Container, Image, Checkbox,Spinner, Grid, GridItem,FormControl, FormLabel, Input, Select, Button, ButtonGroup, InputRightAddon, InputGroup, Modal,ModalOverlay, ModalContent, ModalHeader,ModalCloseButton,ModalBody, ModalFooter } from '@chakra-ui/react'
import { Elements } from "@stripe/react-stripe-js";
import Cookies from 'js-cookie';

import { loadStripe } from "@stripe/stripe-js";
//const stripePromise = await loadStripe("pk_test_xTxkUI4xEvwcy5g79VXZa83G00ozIR3PBp"); // starts with pk_
const stripePromise = await loadStripe("pk_live_xVwah1y9DU0urQpzPPGvLbiM00Pdsq1Ri4"); // starts with pk_



function PaymentBox(Props)
{

	const options = {
      layout: "tabs",
      paymentMethodOrder: ['apple_pay', 'google_pay', 'card'],
     business:{name: 'PDFDom.com'}

};

	const [ is3DSecureOpen, setis3DSecureOpen ] = useState(false);
	const { on3DSecureClose } = useState(false);
	const [ iframeSrc, setIframeSrc ] = useState("");
	const [ clientSecretIntent, setClientSecretIntent ] = useState("");
	const [ paymentIntentId, setPaymentIntentId ] = useState("");
	const [ pendingSubscriptionId, setPendingSubscriptionId ] = useState("");
	const [ currentsubscriptionId, setcurrentSubscriptionId ] = useState("");
	const stripe = useStripe();
	const elements = useElements();
  const [ applePayCancelled, setApplePayCancelled ] = useState(false);
  const [ emailAddress, setEmailAddress ] = useState("");

  const [paymentButtonDisabled , setPaymentButtonDisabled] = useState(false);
  const [paymentButtonLoading , setPaymentButtonLoading] = useState(false);

	const toast = useToast();
  const [paymentRequest, setPaymentRequest] = useState(null);

   // elements.update({amount:99.99*100});

  useEffect(() => {
    if(applePayCancelled)
    {

    /*   if(paymentRequest)
    {
      if(window.confirm('Do you want to retry conversion?')){
          paymentRequest.show();
      }
      //
    }*/

    }


  },[applePayCancelled]);
     useEffect(() => {
    if (stripe) {

          var date = new Date();
    

    // Add five days to current date
    date.setDate(date.getDate() + 3);


      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'PDFDom.com',
          amount: 0,
        },
        requestPayerName: true,
        requestPayerEmail: true,
        applePay: {

          recurringPaymentRequest:{
            paymentDescription:"PDFDom",
            managementURL:"https://www.pdfdom.com",
            regularBilling:{
              amount:1199,
              label:"Subscription",
              recurringPaymentIntervalUnit:"day",
              recurringPaymentIntervalCount:7,
              recurringPaymentStartDate:date.toISOString()
            }
          }
        }
      });

       pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr);
        }

      });

pr.on('cancel', function() {
  setApplePayCancelled(true)
  paymentButtonDisabled(false)
  // handle cancel event
});





           pr.on('paymentmethod', (ev) => {
            console.log(ev.paymentMethod);
            //this event is triggered at the completion of the saved payment 
            //interface
            //we don't care about the actual payment request anymore
            //now that we have a paymentMethod, we can check out as normal
            //the checkout function I am not including here, but is very similar 
            //to the 'create a subscription' stripe guide
            //however it is important to note that I am passing in the event as an 
            //argument.  Once checkout receives a response from my server as to 
            //wether the transaction was successful or not, use 
            //ev.complete('success') and ev.complete('fail') accordingly to close
            //the modal or display an error message (browser specific behavior)


 fetch("https://api.pdfdom.com/create-subscription-public", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          paymentMethod:ev.paymentMethod.id,
          billing_details:ev.paymentMethod.billing_details,

         // name:Props?.profileData?.first_name+" "+Props?.profileData?.last_name,
         // email:Props?.profileData?.email,
          plan:"65b257f686a7b3a30e12c714"
        }),
      })
    .then(function(serverPromise){ 
     return  serverPromise.json()
        .then(function(data) { 
               console.log(data);
         if(data.subscriptionId)
            { 
                  ev.complete('success');

              setcurrentSubscriptionId(data.subscriptionId);

                 Cookies.set('trial_created_no_account', data, { expires: 3 });
                  Props.onPaymentSuccess();


                toast({
          title: 'You are ready to download the file!',
          description: "",
          status: 'success',
          duration: 3000,
          isClosable: true,
        })


                        ReactGA.event({
  category: "payments",
  action: "New Trial Created",
  amount:0,
  label: "new_user_guest", // optional
  nonInteraction: true, // optional, true/false
});


          

            }
         



        })
        .catch(function(e){
          console.log(e);
              ev.complete('fail');

        });
    })
    .catch(function(e){
        console.log(e);
            ev.complete('fail');

      });


            //checkout(ev.paymentMethod.id, ev);
          });


    }
  }, [stripe]);

	const createSubscription = async (id) => {

    if(paymentRequest)
    {
      paymentRequest.show();
    }
    else
    {
      setPaymentButtonDisabled(true);

      try {

     
      var payment_method_id = "";



     
      // create a payment method
      /*const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements?.getElement(CardElement),
        billing_details: {
          name:Props?.profileData?.first_name+" "+Props?.profileData?.last_name,
          email: Props?.profileData?.email
        },
      });*/

         const {error: submitError} = await elements.submit();
  if (submitError) {
    console.log(submitError);
    if(submitError.code!="incomplete_number")
    {
           Props.onPaymentFailed();
           if(paymentRequest)
    {
      paymentRequest.show();
    }

    }
          setPaymentButtonDisabled(false);


    return;
  }

      const paymentMethod = await stripe?.createPaymentMethod({
        elements
      });

      console.log(paymentMethod);
      payment_method_id = paymentMethod?.paymentMethod?.id;
    
   

      // call the backend to create subscription

     

 fetch("https://api.pdfdom.com/create-subscription-public", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          paymentMethod:payment_method_id,
          email:emailAddress,
          plan:"65b257f686a7b3a30e12c714"
        }),
      })
    .then(function(serverPromise){ 
     return  serverPromise.json()
        .then(function(data) { 
               console.log(data);
         if(data.subscriptionId)
            { 
              setcurrentSubscriptionId(data.subscriptionId);

                 Cookies.set('trial_created_no_account', data, { expires: 3 });
                  Props.onPaymentSuccess();


                toast({
          title: 'Conversion has been started!',
          description: "",
          status: 'success',
          duration: 3000,
          isClosable: true,
        })


       
                        ReactGA.event({
  category: "payments",
  action: "New Trial Created",
  amount:0,
  label: "new_user_guest", // optional
  nonInteraction: true, // optional, true/false
});



          

            }
         



        })
        .catch(function(e){
          console.log(e);
                    setPaymentButtonDisabled(false);

        });
    })
    .catch(function(e){
        console.log(e);
                  setPaymentButtonDisabled(false);

      });

   


     

    } catch (error) {
                setPaymentButtonDisabled(false);

      console.log(error);
    }

    }
    
  };

  return (
  	<>
  	<ModalBody>
  	 <Text>Click <strong>"Start Free Trial"</strong> to get a 3-day free access and convert your files</Text>
              <Text fontSize='xs'>Then $9.99/weekly, cancel anytime!</Text>
  	  	{!paymentRequest &&  <PaymentElement options={options}  /> }
        {!paymentRequest &&    <Input placeholder='Your email address' value={emailAddress} size='md' onChange={(ev)=>setEmailAddress(ev.target.value)} /> }

        {/*{paymentRequest && <PaymentRequestButtonElement options={{paymentRequest}} />}*/}


          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} as={'a'} onClick={()=>createSubscription()} isDisabled={paymentButtonDisabled} isLoading={paymentButtonDisabled} loadingText={"Please wait.."} >
              Start Free Trial
            </Button>
          </ModalFooter>
  	<Modal isOpen={is3DSecureOpen} onClose={()=>setis3DSecureOpen(false)}>
        <ModalOverlay />
        <ModalContent>
        <iframe src={iframeSrc} />

         
        </ModalContent>
      </Modal>
      </>

  	);


}


export default PaymentBox;

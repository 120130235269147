'use client'

import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { useToast } from '@chakra-ui/react'
import Cookies from 'js-cookie';
import { Navigate } from 'react-router-dom'

function LoginForm(Props) {
  console.log(Props);
  const toast = useToast()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [buttonIsLoading, setbuttonIsLoading] = useState(false)
  const [isLoggedin, setIsLoggedin] = useState(false);

  useEffect(() => {
   
     if(Cookies.get("id")) {
  console.log(Cookies.get("id"));
    setIsLoggedin(true);
  }

  });
 
   const handlePasswordChange = (event) => {
     setPassword(event.target.value);
     console.log(event.target.value);
    }

     const handleEmailChange = (event) => {
     setEmail(event.target.value);
     console.log(event.target.value);
    }


     const handleFormSubmit = (event) => {
    setbuttonIsLoading(true);

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({  email:email, password: password})

    };
    console.log(requestOptions);
    fetch('https://api.pdfdom.com/api/auth/signin', requestOptions)
        .then(function(response) {
    return response.json();
  }).then(function(data) {
              console.log(data);  
      setbuttonIsLoading(false);

    if(data.error)
    {
      toast({
          title: 'Error.',
          description: data.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
    }
    if(data.id)
    {
      Cookies.set('id', data.id, { expires: 30 });
   Cookies.set('username', data.username, { expires: 30 });
  

toast({
          title: 'Login Succesfful.',
          description: "You will be redirected to dashboard page..",
          status: 'success',
          duration: 9000,
          isClosable: true,
        })

    }


  });



    }

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
      { isLoggedin ?  <Navigate to="/dashboard" /> : null}
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>Sign in to your account</Heading>
          <Text fontSize={'lg'} color={'gray.600'}>
            to enjoy all of our cool <Text color={'blue.400'}>features</Text> ✌️
          </Text>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <Stack spacing={4}>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input type="email" onChange={(e)=>handleEmailChange(e)} />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input type="password" onChange={(e)=>handlePasswordChange(e)} />
            </FormControl>
            <Stack spacing={10}>
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                align={'start'}
                justify={'space-between'}>
                <Checkbox>Remember me</Checkbox>
                <a href="reset-password"><Text color={'blue.400'}>Forgot password?</Text></a>
              </Stack>
              <Button
                bg={'blue.400'}
                isLoading={buttonIsLoading}
                loadingText="Signing in...."
                color={'white'}
                onClick={()=>handleFormSubmit()}
                _hover={{
                  bg: 'blue.500',
                }}>
                Sign in
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  )
}
export default LoginForm
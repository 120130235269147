import logo from '../logo.svg';
import Header from '../parts/header';
import Footer from '../parts/footer';
import styled from 'styled-components';
import { Heading } from '@chakra-ui/react'
import { Center, Square, Circle } from '@chakra-ui/react';
import { Container, Stack, Code } from '@chakra-ui/react';
import { Select, Checkbox } from '@chakra-ui/react'
import {
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  FormHelperText,
} from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Button, ButtonGroup } from '@chakra-ui/react'
import Cookies from 'js-cookie';
import { useState, useEffect } from 'react'
import { useToast } from '@chakra-ui/react'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import {
  AddIcon,
  EditIcon,
  CheckIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'


function Admin() {
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [currentUserId, setCurrentUserId] = useState("");
  const [usersPage, setusersPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [userSearch, setuserSearch] = useState("");
  const [planSearch, setplanSearch] = useState("");
  const [planPage, setplanPage] = useState(1);
  const [totalPlanPages, setTotalPlanPages] = useState(1);

  const toast = useToast()
  const [usersData, setUsersData] = useState([]);
  const [planData, setPlanData] = useState([]);
  const [newPlanModalisOpen, setnewPlanModalisOpen] = useState(false);
  const [newPlan, setNewPlan] = useState({});
  const [editUserModalIsOpen, setEditUserModalIsOpen] = useState(false);
  const [userData, setUserData] = useState({});
  const [roleData, setRoleData] = useState([]);

  useEffect(() => {

    if(Cookies.get("id")) {
      setCurrentUserId(Cookies.get("id"));
      console.log(Cookies.get("id"));
      getUserProfileData(Cookies.get("id"))
    }

  }, []);




  useEffect(() => {
    getUsers(currentUserId);


  }, [totalPages, usersPage, userSearch]);


  useEffect(() => {
    getPlans(currentUserId);


  }, [totalPlanPages, planPage, planSearch]);

  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }


  const planPageNumbers = [];

  for (let i = 1; i <= totalPlanPages; i++) {
    planPageNumbers.push(i);
  }
  const addNewPlan = () => {
      

     const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newPlan)

    };
    console.log(requestOptions);
    fetch('https://api.pdfdom.com/api/admin/new_plan', requestOptions)
    .then(function(response) {
      return response.json();
    }).then(function(data) {
      console.log(data);  
      toast({
          title: 'New Plan Created.',
          description: "",
          status: 'success',
          duration: 3000,
          isClosable: true,
        })

      setNewPlan({});
      getPlans();
    setnewPlanModalisOpen(false);




    });
  }


    const editUser = () => {
      

     const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(userData)

    };
    console.log(requestOptions);
    fetch('https://api.pdfdom.com/api/admin/user_update', requestOptions)
    .then(function(response) {
      return response.json();
    }).then(function(data) {
      console.log(data);  
      toast({
          title: 'User Updated.',
          description: "",
          status: 'success',
          duration: 3000,
          isClosable: true,
        })

     // setNewPlan({});
     // getPlans();
   // setnewPlanModalisOpen(false);
    setEditUserModalIsOpen(false);




    });
  }




    const editPlan = () => {
      

     const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(userData)

    };
    console.log(requestOptions);
    fetch('https://api.pdfdom.com/api/admin/plan_update', requestOptions)
    .then(function(response) {
      return response.json();
    }).then(function(data) {
      console.log(data);  
      toast({
          title: 'User Updated.',
          description: "",
          status: 'success',
          duration: 3000,
          isClosable: true,
        })

     // setNewPlan({});
     // getPlans();
   // setnewPlanModalisOpen(false);
    setEditUserModalIsOpen(false);




    });
  }

     const deletePlan = (plan_id) => {
      

     const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({id:plan_id})

    };
    console.log(requestOptions);
    fetch('https://api.pdfdom.com/api/admin/delete_plan', requestOptions)
    .then(function(response) {
      return response.json();
    }).then(function(data) {
      console.log(data);  
      toast({
          title: 'Plan Deleted.',
          description: "",
          status: 'success',
          duration: 3000,
          isClosable: true,
        })

     // setNewPlan({});
     // getPlans();
   // setnewPlanModalisOpen(false);
    //setEditUserModalIsOpen(false);




    });
  }

 const deleteUser = (user) => {
      

     const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({id:user._id})

    };
    console.log(requestOptions);
    fetch('https://api.pdfdom.com/api/admin/delete_user', requestOptions)
    .then(function(response) {
      return response.json();
    }).then(function(data) {
      console.log(data);  
      toast({
          title: 'User Deleted.',
          description: "",
          status: 'success',
          duration: 3000,
          isClosable: true,
        })

     // setNewPlan({});
     // getPlans();
   // setnewPlanModalisOpen(false);
    //setEditUserModalIsOpen(false);




    });
  }

  const getUsers = (id) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user:Cookies.get("id"),   page:usersPage, search:userSearch})

    };
    console.log(requestOptions);
    fetch('https://api.pdfdom.com/api/admin/users', requestOptions)
    .then(function(response) {
      return response.json();
    }).then(function(data) {
      console.log(data);  


      setTotalPages(data.pages)


      setUsersData(data.users);

      setRoleData(data.roles);




    });



  }

  const onAddNewPlanHandler = () =>
  {

    setnewPlanModalisOpen(true);


  }

   const onRolesChange = (e) =>
  {
    console.log(e.target.value);
  if(e.target.checked )
  {
     setUserData(  {...userData, roles: [...userData.roles, e.target.value]})

  }
  else
  {

    setUserData( {...userData, roles: userData?.roles.filter(role => role !== e.target.value)});


   //  setUserData( {roles: userData?.roles.filter(role => role !== e.target.value)}  );

  }

  }

   const onEditUserHandler = (user) =>
  {
    user.password = "";
    setUserData(user);
    setEditUserModalIsOpen(true);


  }

  const getPlans = (id) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({  page:usersPage, search:userSearch})

    };
    console.log(requestOptions);
    fetch('https://api.pdfdom.com/api/admin/plans', requestOptions)
    .then(function(response) {
      return response.json();
    }).then(function(data) {
      console.log(data);  


      setTotalPlanPages(data.pages)


      setPlanData(data.plans);





    });



  }
  const getUserProfileData = (id) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({  id:id})

    };
    console.log(requestOptions);
    fetch('https://api.pdfdom.com/api/user/profile', requestOptions)
    .then(function(response) {
      return response.json();
    }).then(function(data) {
      console.log(data);  



      if(data.id)
      {

        setProfileData(data);


      }


    });



  }

  const onPageClickHandler = (number) => {

    setusersPage(number);
  }


  const onPlanPageClickHandler = (number) => {

    setplanPage(number);
  }

  const updateProfile = () => {
    console.log(profileData);
    var data_to_send = profileData;
    data_to_send.id = currentUserId;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data_to_send)

    };
    console.log(requestOptions);
    fetch('https://api.pdfdom.com/api/user/profile_update', requestOptions)
    .then(function(response) {
      return response.json();
    }).then(function(data) {
      console.log(data);  



      if(data.id)
      {

        toast({
          title: 'Profile Updated.',
          description: "",
          status: 'success',
          duration: 3000,
          isClosable: true,
        })


      }


    });



  }


  return (

    <div className="home">
    <Header/>



    <Center  h='100px' color='black'>

    <Heading as='h2' noOfLines={1}>
    Admin
    </Heading>
    </Center>

    <Tabs>
    <TabList>
    <Tab>Users</Tab>
    <Tab>Plans</Tab>
    <Tab>Stats</Tab>
    <Tab>Configuration</Tab>
    </TabList>

    <TabPanels>
    <TabPanel>
    <TableContainer>
    <Input placeholder='Search....' onInput={(e)=>setuserSearch(e.target.value)} />

    <Table variant='simple'>
    <TableCaption>Users</TableCaption>
    <Thead>
    <Tr>
    <Th>Email</Th>
    <Th>First Name</Th>
    <Th>Last Name</Th>
    <Th>Registration Date</Th>
    <Th>Actions</Th>
    <Th>Active</Th>
    </Tr>
    </Thead>
    <Tbody>
    {usersData.map(function(item, i){
      console.log(item);
      return (<Tr>
        <Td>{item.email}</Td>
        <Td>{item.first_name}</Td>
        <Td>{item.last_name}</Td>

        <Td>{item?.registration_date}</Td>
        <Td><Button leftIcon={<EditIcon />} colorScheme='teal' variant='solid' onClick={()=>onEditUserHandler(item)} >

        Edit
        </Button>
        <Button leftIcon={<EditIcon />} colorScheme='teal' variant='solid' onClick={()=>deleteUser(item)} >

        Delete
        </Button></Td>
        <Td><CheckIcon/></Td>

        </Tr>);
    })}

    </Tbody>

    </Table>
    </TableContainer>
    {pageNumbers.map(number => {
      return (
        <Button variant={usersPage == number ?  "solid" : "outline"} onClick={()=>onPageClickHandler(number)}>{number}</Button>
        );
    })}


    </TabPanel>
    <TabPanel>
    
    <Button leftIcon={<AddIcon />} colorScheme='teal' variant='solid' onClick={()=>onAddNewPlanHandler()}>Add New Plan</Button>

    <TableContainer>
    <Input placeholder='Search....' onInput={(e)=>setplanSearch(e.target.value)} />

    <Table variant='simple'>
    <TableCaption>Plans</TableCaption>
    <Thead>
    <Tr>
    <Th>Name</Th>
    <Th>Price</Th>
    <Th>Billing Cycle</Th>
    <Th>Has Free Trial</Th>
    <Th>Trial Days</Th>
    <Th>Enabled</Th>
        <Th>Actions</Th>

    </Tr>
    </Thead>
    <Tbody>
    {planData.map(function(item, i){
      return (<Tr>
        <Td>{item.name}</Td>
        <Td>{item.pricing}</Td>
        <Td>{item.billing_cycle}</Td>
        <Td>{item?.has_trial ? <CheckIcon/> : ''}</Td>
        <Td>{item.trial_days}</Td>

        <Td>{item?.enabled ? <CheckIcon/> : ''}</Td>

        <Td><Button variant="solid" onClick={()=>deletePlan(item._id)}>Delete</Button></Td>

        </Tr>);
    })}

    </Tbody>

    </Table>
    </TableContainer>
    {planPageNumbers.map(number => {
      return (
        <Button variant={planPage == number ?  "solid" : "outline"} onClick={()=>onPlanPageClickHandler(number)}>{number}</Button>
        );
    })}

    </TabPanel>
    <TabPanel>
    </TabPanel>
    <TabPanel>

    </TabPanel>
    </TabPanels>
    </Tabs>


    <Modal

    isOpen={newPlanModalisOpen}
    onClose={()=>setnewPlanModalisOpen(false)}
    >
    <ModalOverlay />
    <ModalContent>
    <ModalHeader>New Pricing Plan</ModalHeader>
    <ModalCloseButton />
    <ModalBody pb={6}>
    <Stack spacing={4}>

    <InputGroup>

    <Input placeholder='Plan Name' value={newPlan?.name} onChange={(e)=>setNewPlan({...newPlan, name:e.target.value})} />
    <InputRightElement>
    <CheckIcon color='green.500'  />
    </InputRightElement>
    </InputGroup>


    <InputGroup>
    <InputLeftElement
    pointerEvents='none'
    color='gray.300'
    fontSize='1.2em'
    children='$'
    />
    <Input placeholder='Pricing'  value={newPlan?.pricing} onChange={(e)=>setNewPlan({...newPlan, pricing:e.target.value})} />
    <InputRightElement>
    <CheckIcon color='green.500' />
    </InputRightElement>
    </InputGroup>

    <InputGroup>

    <Select placeholder='Billing Cycle' value={newPlan?.billing_cycle} onChange={(e)=>setNewPlan({...newPlan, billing_cycle:e.target.value})}>
    <option value='weekly'>Weekly</option>
    <option value='monthly'>Monthly</option>
    <option value='yearly'>Yearly</option>
    </Select>
    </InputGroup>


    <InputGroup>

  <Checkbox size='md' colorScheme='green' isChecked={newPlan?.has_trial} onChange={(e)=>setNewPlan({...newPlan, has_trial:e.target.checked})}>
    Free Trial
  </Checkbox>
    </InputGroup>

     <InputGroup>

    <Select placeholder='Trial Days' value={newPlan?.trial_days} onChange={(e)=>setNewPlan({...newPlan, trial_days:e.target.value})}>
    <option value='3'>3 Day</option>
    <option value='7'>7 Days</option>
   
    <option value='14'>14 Days</option>
    <option value='30'>30 Days</option>
    </Select>
    </InputGroup>

     <InputGroup>

      <Checkbox size='md' colorScheme='green' isChecked={newPlan?.enabled} onChange={(e)=>setNewPlan({...newPlan, enabled:e.target.checked})}>
        Enable Plan
      </Checkbox>
    </InputGroup>



    </Stack>
    </ModalBody>

    <ModalFooter>
    <Button colorScheme='blue' mr={3} onClick={()=>addNewPlan()}>
    Save 
    </Button>
    <Button onClick={()=>setnewPlanModalisOpen(false)}>Cancel</Button>
    </ModalFooter>
    </ModalContent>
    </Modal>



    <Modal

    isOpen={editUserModalIsOpen}
    onClose={()=>setEditUserModalIsOpen(false)}
    >
    <ModalOverlay />
    <ModalContent>
    <ModalHeader>Edit User {userData?.username}</ModalHeader>
    <ModalCloseButton />
    <ModalBody pb={6}>
    <Stack spacing={4}>

    <InputGroup>

    <Input placeholder='First Name' value={userData?.first_name} onChange={(e)=>setUserData({...userData, first_name:e.target.value})} />
    <InputRightElement>
    <CheckIcon color='green.500'  />
    </InputRightElement>
    </InputGroup>


    <InputGroup>

    <Input placeholder='Last Name' value={userData?.last_name} onChange={(e)=>setUserData({...userData, last_name:e.target.value})} />
    <InputRightElement>
    <CheckIcon color='green.500'  />
    </InputRightElement>
    </InputGroup>

     <InputGroup>

    <Input placeholder='Email' value={userData?.email} onChange={(e)=>setUserData({...userData, email:e.target.value})} />
    <InputRightElement>
    <CheckIcon color='green.500'  />
    </InputRightElement>
    </InputGroup>


     <InputGroup>

    <Input placeholder='Password' value={userData?.password} onChange={(e)=>setUserData({...userData, password:e.target.value})} />
    <InputRightElement>
    <CheckIcon color='green.500'  />
    </InputRightElement>
    </InputGroup>






    <InputGroup>

    <Input placeholder='Company Name' value={userData?.company_name} onChange={(e)=>setUserData({...userData, company_name:e.target.value})} />
    <InputRightElement>
    <CheckIcon color='green.500'  />
    </InputRightElement>
    </InputGroup>


    <InputGroup>

    <Input placeholder='VAT' value={userData?.vat} onChange={(e)=>setUserData({...userData, vat:e.target.value})} />
    <InputRightElement>
    <CheckIcon color='green.500'  />
    </InputRightElement>
    </InputGroup>
    


    <InputGroup>

  {roleData.map(role => {
      return (
        <Checkbox value={role._id} onChange={(e)=>onRolesChange(e)} isChecked={userData?.roles?.includes(role._id)}>{role.name}</Checkbox>
        );
    })}
  
    </InputGroup>



    



    </Stack>
    </ModalBody>

    <ModalFooter>
    <Button colorScheme='blue' mr={3} onClick={()=>editUser()}>
    Save 
    </Button>
    <Button onClick={()=>setnewPlanModalisOpen(false)}>Cancel</Button>
    </ModalFooter>
    </ModalContent>
    </Modal>


    <Footer/>

    </div>

    );
}

export default Admin;

import logo from '../logo.svg';
import Header from '../parts/header';
import Footer from '../parts/footer';
import Pricing from './home-parts/pricing';
import LoginForm from './reset-parts/resetform';
import {Helmet} from "react-helmet";

function Reset() {
  return (

    <div className="home">
     <Helmet>
           <meta charSet="utf-8" />
            <title>Reset Password -  PDFDom</title>
             <meta name="description" content="Join in for convenient access to our bulk pdf conversion services." />

            <link rel="canonical" href="https://www.pdfdom.com/reset-password" />
            </Helmet>
      <Header/>

   		<LoginForm/>

          <Footer/>

    </div>

  );
}

export default Reset;
'use client'
import {
  Link
} from "react-router-dom";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  useColorMode
} from '@chakra-ui/react'
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons'
import Cookies from 'js-cookie';
import { useState, useEffect } from 'react'
import { useToast } from '@chakra-ui/react'
import {Helmet} from "react-helmet";
import Hotjar from '@hotjar/browser';

import ReactGA from "react-ga4";
ReactGA.initialize("G-VT69M629DM");
const siteId = 3848342;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);



 function Header() {
  const { colorMode, toggleColorMode } = useColorMode()

  const { isOpen, onToggle } = useDisclosure()
    const toast = useToast()

    const [isLoggedin, setIsLoggedin] = useState(false);
useEffect(() => {
   
     if(Cookies.get("id")) {
  console.log(Cookies.get("id"));
    setIsLoggedin(true);
  }

  });
  const [buttonIsLoading, setbuttonIsLoading] = useState(false)


  const handleSignout = (event) => {
    setbuttonIsLoading(true);

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
       // body: JSON.stringify({  email:email, password: password})

    };
    console.log(requestOptions);
    fetch('https://api.pdfdom.com/api/auth/signout', requestOptions)
        .then(function(response) {
    return response.json();
  }).then(function(data) {
              console.log(data);  
      setbuttonIsLoading(false);

    if(data.error)
    {
      toast({
          title: 'Error.',
          description: data.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
    }
    if(data.status =="signedout")
    {


   Cookies.remove("id");
     Cookies.remove("username");
     setIsLoggedin(false);

toast({
          title: 'Signed Out.',
          description: "You will be redirected to home page..",
          status: 'success',
          duration: 9000,
          isClosable: true,
        })

    }


  });



    }



  return (
    <Box>
    <Helmet>
<meta           name="viewport"           content="width=device-width,minimum-scale=1,initial-scale=1"         />
            </Helmet>
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}>
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}>
          <IconButton
            onClick={onToggle}
            icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          <a href = {'/'} ><Text

            textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
            fontFamily={'heading'}
            color={useColorModeValue('gray.800', 'white')}>
            <img src="imgs/logo.png" style={{height:'30px'}}/>
          </Text>
          </a>

          <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
            <DesktopNav />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={'flex-end'}
          direction={'row'}
          spacing={6}>
           { !isLoggedin ?
          <Button as={'a'} fontSize={'sm'} fontWeight={400} variant={'link'} href={'/sign-in'}>
            Sign In
          </Button>:null}
         { !isLoggedin ? <Button
            as={'a'}
            isLoading={buttonIsLoading}
            display={{ base: 'none', md: 'inline-flex' }}
            fontSize={'sm'}
            fontWeight={600}
             bg='gray.100'

            color={'white'}
            href={'/sign-up'}
            >
            Sign Up
          </Button> : null}
           { isLoggedin ? <Button
            as={'a'}
            display={{ base: 'none', md: 'inline-flex' }}
            fontSize={'sm'}
            onClick={()=>handleSignout()}
            fontWeight={600}
            loadingtext="Signing out..">
           Logout
          </Button>:null}

          { isLoggedin ? <Button
            as={'a'}
            display={{ base: 'none', md: 'inline-flex' }}
            fontSize={'sm'}
            href={'/my-account'}
            fontWeight={600}
            loadingtext="Signing out..">
           My Account
          </Button>:null}
                 { isLoggedin ? <Button
            as={'a'}
            display={{ base: 'none', md: 'inline-flex' }}
            fontSize={'sm'}
            href={'/dashboard'}
            fontWeight={600}
            loadingtext="Signing out..">
           Dashboard
          </Button>:null}
            <Button size='sm' onClick={toggleColorMode}>
               Toggle {colorMode === 'light' ? 'Dark' : 'Light'}

      </Button>
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  )
}

const DesktopNav = () => {
  const linkColor = useColorModeValue('gray.600', 'gray.200')
  const linkHoverColor = useColorModeValue('gray.800', 'white')
  const popoverContentBgColor = useColorModeValue('white', 'gray.800')

  return (
    <Stack direction={'row'} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Box
                as="a"
                p={2}
                href={navItem.href ?? '#'}
                fontSize={'sm'}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}>
                {navItem.label}
              </Box>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}>
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  )
}

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  return (
    <Box
      as="a"
      href={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}>
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: 'pink.400' }}
            fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Box>
  )
}

const MobileNav = () => {
  return (
    <Stack bg={useColorModeValue('white', 'gray.800')} p={4} display={{ md: 'none' }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  )
}

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure()

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Box
        py={2}
        as="a"
        href={href ?? '#'}
        justifyContent="space-between"
        alignItems="center"
        _hover={{
          textDecoration: 'none',
        }}>
        <Text fontWeight={600} color={useColorModeValue('gray.600', 'gray.200')}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Box>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}>
          {children &&
            children.map((child) => (
              <Box as="a" key={child.label} py={2} href={child.href}>
                {child.label}
              </Box>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  )
}

interface NavItem {
  label: string,
  subLabel?: string,
  children?: Array<NavItem>,
  href?: string
}

const NAV_ITEMS: Array<NavItem> = [
 
  {
    label: 'PDF to Word',
    href: 'pdf-to-word',

  }
]


export default Header;

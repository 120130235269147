'use client'

import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { useToast } from '@chakra-ui/react'
import Cookies from 'js-cookie';
import { Navigate } from 'react-router-dom'
import { useSearchParams } from "react-router-dom";

function ResetForm(Props) {
  console.log(Props);
  const toast = useToast()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [buttonIsLoading, setbuttonIsLoading] = useState(false)
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
   
     if(Cookies.get("id")) {
  console.log(Cookies.get("id"));
    setIsLoggedin(true);
  }



  });
 
   const handlePasswordChange = (event) => {
     setPassword(event.target.value);
     console.log(event.target.value);
    }

     const handleEmailChange = (event) => {
     setEmail(event.target.value);
     console.log(event.target.value);
    }


     const handleFormSubmit = (event) => {
    setbuttonIsLoading(true);

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({  email:email, password: password})

    };
    console.log(requestOptions);
    fetch('https://api.pdfdom.com/api/auth/reset-password', requestOptions)
        .then(function(response) {
    return response.json();
  }).then(function(data) {
              console.log(data);  
      setbuttonIsLoading(false);

    if(data.error)
    {
      toast({
          title: 'Error.',
          description: data.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
    }
    if(data.success)
    {
     /* Cookies.set('id', data.id, { expires: 30 });
   Cookies.set('username', data.username, { expires: 30 });
  */

toast({
          title: 'Email Sent.',
          description: data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        })

    }


  });



    }

         const handleNewPasswordSubmit = (event) => {
    setbuttonIsLoading(true);

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token:searchParams.get("token"), password: password})

    };
    console.log(requestOptions);
    fetch('https://api.pdfdom.com/api/auth/reset-password', requestOptions)
        .then(function(response) {
    return response.json();
  }).then(function(data) {
              console.log(data);  
      setbuttonIsLoading(false);

    if(data.error)
    {
      toast({
          title: 'Error.',
          description: data.error,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
    }
    if(data.success)
    {
     /* Cookies.set('id', data.id, { expires: 30 });
   Cookies.set('username', data.username, { expires: 30 });
  */

toast({
          title: 'Password Changed.',
          description: data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        })

    }


  });



    }

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
      { isLoggedin ?  <Navigate to="/dashboard" /> : null}
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>Reset your Password</Heading>
          

        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          

          <Stack spacing={4} style={searchParams.get("token") ? {display:'None'}: {}}>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input type="email" onChange={(e)=>handleEmailChange(e)} />
            </FormControl>
            <Stack spacing={10}>
         
              <Button
                bg={'blue.400'}
                isLoading={buttonIsLoading}
                loadingText="Processing.."
                color={'white'}
                onClick={()=>handleFormSubmit()}
                _hover={{
                  bg: 'blue.500',
                }}>
                Password Reset
              </Button>
            </Stack>
          </Stack>


          <Stack spacing={4} style={searchParams.get("token") ? {}: {display:'None'}}>
            <FormControl id="password">
              <FormLabel>Set New Password</FormLabel>
              <Input type="password" onChange={(e)=>handlePasswordChange(e)} />
            </FormControl>
            <Stack spacing={10}>
         
              <Button
                bg={'blue.400'}
                isLoading={buttonIsLoading}
                loadingText="Processing.."
                color={'white'}
                onClick={()=>handleNewPasswordSubmit()}
                _hover={{
                  bg: 'blue.500',
                }}>
                Confirm New Password
              </Button>
            </Stack>
          </Stack>


        </Box>
      </Stack>
    </Flex>
  )
}
export default ResetForm
import logo from '../logo.svg';
import Header from '../parts/header';
import Footer from '../parts/footer';
import styled from 'styled-components';
import { Heading } from '@chakra-ui/react'
import { Center, Square, Circle } from '@chakra-ui/react'
import { Container, Stack, Code, Box,Text, HStack,VStack,ListItem, ListIcon, useColorModeValue, List, Select, InputGroup, InputRightElement, Alert, AlertIcon } from '@chakra-ui/react'
import {
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Button, ButtonGroup } from '@chakra-ui/react'
import Cookies from 'js-cookie';
import { useState, useEffect } from 'react'
import { useToast } from '@chakra-ui/react'
import InvoicesForm from './account-parts/invoicesform'
import {PriceWrapper} from './home-parts/pricing'
import { FaCheckCircle } from 'react-icons/fa'
import Plans from './account-parts/plans'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {Helmet} from "react-helmet";
import countries_json from './countries.json';
import { useSearchParams } from "react-router-dom";

 const stripePromise = loadStripe("pk_test_xTxkUI4xEvwcy5g79VXZa83G00ozIR3PBp"); // starts with pk_

function MyAccount() {
  const [isLoggedin, setIsLoggedin] = useState(false);
    const [profileData, setProfileData] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [emailConfirmedVisible, setEmailConfirmedVisible] = useState(false);
  const [defaultTabIndex, setDefaultTabIndex] = useState(1);
        const [changeTrigger, setChangeTrigger] = useState("");

    const [currentUserId, setCurrentUserId] = useState("");
  const toast = useToast()
    const [plans, setPlans] = useState([]);

     const [selectedPackage, setSelectedPackaged] = useState(0);

  const handlePackageSelectionChange = (newValue) => {
    setSelectedPackaged(newValue);
  };

  useEffect(() => {
   
     if(Cookies.get("id")) {
      setCurrentUserId(Cookies.get("id"));
  console.log(Cookies.get("id"));
    getUserProfileData(Cookies.get("id"))

  }
  getPlans(Cookies.get("id"));
  if(searchParams.get("email-confirm"))
  {
    email_confirmation();
  }

 

  }, [changeTrigger]);


 const getUserProfileData = (id) => {

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({  id:id})

    };
    console.log(requestOptions);
    fetch('https://api.pdfdom.com/api/user/profile', requestOptions)
        .then(function(response) {
    return response.json();
  }).then(function(data) {
              console.log(data);  


 
    if(data.id)
    {
     
        setProfileData(data);


    }


  });



    }




 const email_confirmation = () => {

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({  token:searchParams.get("email-confirm")})

    };
    console.log(requestOptions);
    fetch('https://api.pdfdom.com/api/auth/email-confirm', requestOptions)
        .then(function(response) {
    return response.json();
  }).then(function(data) {
              console.log(data);  

              setEmailConfirmedVisible(true);
 
 


  });



    }




     const activatePlan = (id) => {

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({  id:id})

    };
    console.log(requestOptions);
    fetch('https://api.pdfdom.com/api/user/profile', requestOptions)
        .then(function(response) {
    return response.json();
  }).then(function(data) {
              console.log(data);  


 
    if(data.id)
    {
     
        setProfileData(data);


    }


  });



    }

     const getPlans = (id) => {

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({  id:id})

    };
    console.log(requestOptions);
    fetch('https://api.pdfdom.com/api/public/plans', requestOptions)
        .then(function(response) {
    return response.json();
  }).then(function(data) {
              console.log(data);  


 
     
        setPlans(data.plans);


  


  });



    }

     const updateProfile = () => {
      console.log(profileData);
      var data_to_send = profileData;
      data_to_send.id = currentUserId;
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data_to_send)

    };
    console.log(requestOptions);
    fetch('https://api.pdfdom.com/api/user/profile_update', requestOptions)
        .then(function(response) {
    return response.json();
  }).then(function(data) {
              console.log(data);  


 
    if(data.id)
    {
     
        toast({
          title: 'Profile Updated.',
          description: "",
          status: 'success',
          duration: 3000,
          isClosable: true,
        })

setChangeTrigger(Math.random().toString(36).slice(2, 7));
    }


  });



    }
    const options = {
  mode: 'payment',
  currency: 'usd',
  amount: selectedPackage*100,
  setup_future_usage: 'off_session',
  mode: 'subscription',
  paymentMethodCreation:'manual',

};
  return (

    <div className="home">
       <Helmet>
           <meta charSet="utf-8" />
            <title>MyAccount- PDFDom</title>
              <meta name="description" content="Manage your PDFDom account . Manage Payment Methods and API Access " />

            <link rel="canonical" href="https://www.pdfdom.com/my-account" />
            </Helmet>
      <Header/>
   <Alert status='warning' style={!profileData?.email_confirmed ? {}: {display:'None'}}>
    <AlertIcon />
    Your Email has not been verifed yet. Please verify your email address
  </Alert>
  <Alert status='success' style={emailConfirmedVisible ? {}: {display:'None'}}>
    <AlertIcon />
    Your Email has been succesfully confirmed!
  </Alert>

      <Container>
   

      <Center  h='100px'>

       <Heading as='h2' noOfLines={1} color={'black.100'}>
    My Account
  </Heading>
    </Center>

  <Tabs defaultIndex={defaultTabIndex}>
  <TabList>
    <Tab>Profile</Tab>
    <Tab>Plan</Tab>
    <Tab>Invoices</Tab>
     <Tab>API</Tab>
  </TabList>

  <TabPanels>
    <TabPanel>

      <FormControl>
      <FormLabel>First Name</FormLabel>
      <Input type='text' value={profileData?.first_name} onChange={(evt)=> setProfileData(prevState => ({
    ...prevState,
    first_name: evt.target.value,
}))} />
      <FormHelperText></FormHelperText>
      </FormControl>

      <FormControl>
      <FormLabel>Last Name</FormLabel>
      <Input type='text' value={profileData?.last_name} onChange={(evt)=> setProfileData(prevState => ({
    ...prevState,
    last_name: evt.target.value,
}))} />
      <FormHelperText></FormHelperText>
      </FormControl>



      <FormControl>
      <FormLabel>Country</FormLabel>
     
       <Select placeholder='Select Country' value={profileData?.country_code} onChange={(evt)=> setProfileData(prevState => ({
    ...prevState,
    country_code: evt.target.value,
}))}>
       {
          countries_json.map(function(item, i){
            return (
  <option value={item.code}>{item.name}</option>

              )

          })}
       

</Select>

      <FormHelperText></FormHelperText>
      </FormControl>


          <FormControl>
      <FormLabel>Address</FormLabel>
      <Input type='text' value={profileData?.address} onChange={(evt)=> setProfileData(prevState => ({
    ...prevState,
    address: evt.target.value,
}))} />
      <FormHelperText></FormHelperText>
      </FormControl>

        <FormControl>
      <FormLabel>Postal Code</FormLabel>
      <Input type='text' value={profileData?.postal} onChange={(evt)=> setProfileData(prevState => ({
    ...prevState,
    postal: evt.target.value,
}))} />
      <FormHelperText></FormHelperText>
      </FormControl>


       <FormControl>
      <FormLabel>City</FormLabel>
      <Input type='text' value={profileData?.city} onChange={(evt)=> setProfileData(prevState => ({
    ...prevState,
    city: evt.target.value,
}))} />
      <FormHelperText></FormHelperText>
      </FormControl>

         <FormControl>
      <FormLabel>State</FormLabel>
      <Input type='text' value={profileData?.state} onChange={(evt)=> setProfileData(prevState => ({
    ...prevState,
    state: evt.target.value,
}))} />
      <FormHelperText></FormHelperText>
      </FormControl>


     

      <FormControl>
      <FormLabel>Company Name</FormLabel>
      <Input type='text' value={profileData?.company_name} onChange={(evt)=> setProfileData(prevState => ({
    ...prevState,
    company_name: evt.target.value,
}))} />
      <FormHelperText>Fill your Company Name (if you have one)</FormHelperText>
      </FormControl>

         <FormControl style={profileData?.company_name ? {display:'initial'} : {display:'none'}}>
      <FormLabel>VAT ID</FormLabel>
      <Input type='text' value={profileData?.vat} onChange={(evt)=> setProfileData(prevState => ({
    ...prevState,
    vat: evt.target.value,
}))} />
      <FormHelperText>Complete your VAT ID. (Only if you have one)</FormHelperText>
      </FormControl>


      
      <FormControl>
      <FormLabel>Email address</FormLabel>
      <Input type='email' value={profileData?.email} disabled/>
      <FormHelperText>We'll never share your email.</FormHelperText>
      </FormControl>

      <FormControl>
      <FormLabel>Change Password</FormLabel>
      <Input type='password' />
      <FormHelperText>Fill it only if you want to change your password</FormHelperText>
      </FormControl>

       <Button colorScheme='teal' size='md' onClick={()=>updateProfile()}>
        Save Profile
        </Button>



    </TabPanel>
    <TabPanel>
    <Elements stripe={stripePromise} options={options}>

    <Plans plans={plans} profileData={profileData} setPackageToParrent={handlePackageSelectionChange}/>
    </Elements>
    </TabPanel>
    <TabPanel>
      <InvoicesForm UserId={Cookies.get("id")}/>
    </TabPanel>
     <TabPanel>
      

  <Stack direction='row'>
  <InputGroup size='md'>
      <Input
        pr='4.5rem'
        type='text'
        placeholder={profileData?.api_key}
        value={profileData?.api_key}
        variant='filled'
      />
      <InputRightElement width='4.5rem'>
        <Button h='1.75rem' size='sm' variant='flashed'>
          Copy
        </Button>
      </InputRightElement>
    </InputGroup>
</Stack>




    </TabPanel>
  </TabPanels>
</Tabs>
</Container>

          <Footer/>

    </div>

  );
}

export default MyAccount;

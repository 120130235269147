import logo from '../logo.svg';
import Header from '../parts/header';
import Footer from '../parts/footer';
import TestimonialWithSpeechBubbles from './home-parts/testimonials';
import Pricing from './home-parts/pricing';
import {Helmet} from "react-helmet";
import BasicStatistics from './home-parts/stats';
import { useState, useEffect } from 'react'

function Home() {

      const [plans, setPlans] = useState([]);

  useEffect(() => {
    getPlans();



  }, []);



  const getPlans = async () => {

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({})

    };
    console.log(requestOptions);
    fetch('https://api.pdfdom.com/api/public/plans_public', requestOptions)
        .then(function(response) {
    return response.json();
  }).then(function(data) {
              console.log(data);  


 
     
        setPlans(data.plans);


  


  });



    }


  return (


    <div className="home">
    <Helmet>
             <meta charSet="utf-8" />
              <title>Convert PDF Files to Various formats with pdfDom</title>
              <meta name="description" content="Convert PDF to Word, JPEG and other formats for Free" />

            <link rel="canonical" href="https://www.pdfdom.com/" />
            </Helmet>

      <Header/>
            <BasicStatistics/>

      <TestimonialWithSpeechBubbles/>
            <Pricing plans={plans}/>

          <Footer/>

    </div>

  );
}

export default Home;

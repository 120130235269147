import logo from '../logo.svg';
import Header from '../parts/header';
import Footer from '../parts/footer';
import {Helmet} from "react-helmet";

function TermsOfService() {
  return (

    <div className="home">
       <Helmet>
           <meta charSet="utf-8" />
            <title>Terms of  Service -  PDFDom</title>
             <meta name="description" content="" />

            <link rel="canonical" href="https://www.pdfdom.com/" />
            </Helmet>
      <Header/>


<div className="content">
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          Last Updated: January 25th, 2024
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", textIndent: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          These Terms of Service ("Terms", "Terms of Service") are entered into
          by and between Mediaheap OU., An Estonian  Limited Liability Company ("Mediaheap",
          "Company", "Us", "pdfdom.com"), and its Users and visitors to the
          website or service "pdfdom.com", and together with the Privacy Policy,
          govern those persons' access to the Services, as well as any content
          or functionality of the Services, whether as a visitor or a User. By
          accessing or using the pdfdom.com website or service ("the Service"),
          including any content or functionality thereof, you agree to be bound
          by these Terms, which also incorporate our Privacy Policy.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", textIndent: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          In the context of these Terms, references to "customer", "client",
          "you", or "your" shall mean the individual who accesses, uses, and/or
          participates in the Website in any manner, including their heirs,
          assigns, and successors. If you are using the Website on behalf of an
          entity, organization, or company, you represent and warrant that you
          have the authority to bind that entity to these Terms. In such cases,
          your acceptance of the Terms will be considered as acceptance by the
          respective entity, and the terms "you" and "your" herein shall refer
          to that entity.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", textIndent: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>
            THE SERVICE PROVIDED BY pdfdom.com IS STRICTLY FOR CONVERTING FILES
            FROM ONE FORMAT TO ANOTHER. THE RESULTING FILES ARE BASED SOLELY ON
            USER INPUT AND PREFERENCES. YOU, AS THE USER, ACKNOWLEDGE AND AGREE
            THAT YOU ARE SOLELY RESPONSIBLE FOR THE FILES YOU UPLOAD AND THEIR
            CONTENT. pdfdom.com DISCLAIMS ANY LIABILITY FOR THE USAGE OF THE
            SERVICE OR THE RESULTS OBTAINED FROM IT.
          </b>
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", textIndent: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          By accessing or using the Service, you agree to comply with and be
          bound by these Terms of Service outlined in this user agreement ("the
          Terms" or "the Agreement"), regardless of whether you become a
          registered user of the Service. Failure to adhere to these Terms and
          the accompanying Privacy Policy may subject you to civil and criminal
          penalties.
        </font>
      </font>
    </font>
  </p>
  <p style={{ lineHeight: "150%", marginBottom: "0in" }}>
    <br />
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>1.&nbsp; Service.</b>
        </font>
      </font>
    </font>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          pdfdom.com offers an online file conversion service ("Service") that
          enables users to convert files from one format to another. Users can
          easily convert their files by uploading the desired file and selecting
          the desired output format on the Website. The conversion process is
          facilitated through our advanced file conversion technology. The
          accuracy and quality of the converted file depend on the input file's
          format and the chosen output format. Users are responsible for
          ensuring the suitability and compatibility of the files for
          conversion.
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>2.&nbsp; Accounts and Security.</b>
        </font>
      </font>
    </font>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          To access the Services, you must have an account. You must maintain
          and are responsible for the confidentiality of your login and
          password. If requested, you must provide us with a form of
          identification to verify your identity.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", textIndent: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          a.&nbsp; You may not use our Services if:
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", marginLeft: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          b.&nbsp; Children are not eligible to use our Services, and we ask
          that anyone under the age of 18 years old not submit any personal
          information to us. Our Services are not directed at anyone under the
          age of 18 years old. We also do not collect or maintain personally
          identifiable information from those Users who we know are under the
          age of 18 years old. Should we learn or be notified that we have
          collected information from Users under the age of 18 years old, we
          will immediately delete such personally identifiable information.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", textIndent: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          c.&nbsp; You have previously been banned from using our Services or
          similar services.
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>3.&nbsp; Conditions Of Access.</b>
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", textIndent: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          a.&nbsp; You shall select a username when signing up for our Services.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", textIndent: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          b.&nbsp; You shall abide by all Terms of Service and our Privacy
          Policy.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", textIndent: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          c.&nbsp; You may be required to verify that each party is consenting
          to Services.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", marginLeft: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          d.&nbsp; When you sign up, you will become a Member of the particular
          brand of the Services for which you have registered.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", marginLeft: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          e.&nbsp; Your login information shall be personal and confidential.
          Each Member shall keep them confidential and undertakes not to notify
          or disclose them to third parties or other Members to prevent fraud or
          phishing.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", marginLeft: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          f.&nbsp; All Users undertake not to use another Member’s login
          information or any other personal information of another User.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", marginLeft: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          g.&nbsp; Any breach of these provisions may lead to the cancellation
          of a Member’s Membership.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", marginLeft: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          h.&nbsp; Each Member shall take care not to disclose strictly personal
          information.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", marginLeft: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          i.&nbsp; We will take any necessary measures to halt fraudulent
          behavior.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", marginLeft: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          j.&nbsp; Each User undertakes not to carry out any action likely to
          hinder the operation of the Services and undertakes not to disseminate
          or arrange for the dissemination of viruses, spam, logic bombs,
          software Websites, etc.
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>4.&nbsp; Paid Features.</b>
        </font>
      </font>
    </font>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          You may purchase a paid Membership for one or more of the products
          offered by the Company. Your paid Membership will only give you access
          to the particular Company product for which you have registered. You
          will not be a Member of those other Services without registering for
          them specifically.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", marginLeft: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          a.&nbsp; The price and payment procedures are permanently accessible
          on the Websites.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", marginLeft: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          b.&nbsp; All prices stated include all relevant local taxes.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", marginLeft: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          c.&nbsp; We reserve the right to change the cost of any of our
          Services. If you are not happy with the cost of any Services, you may
          cancel your Membership in accordance with these Terms of Use.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", marginLeft: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          d.&nbsp; To the extent permitted by applicable laws and regulations,
          no refunds will be offered (except in exceptional circumstances such
          as major service failures). There will be no refund of any payment
          made by you for a paid Membership if you haven’t canceled your
          contract according to these Terms of Use.
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>5.&nbsp; Trial Period.</b>
        </font>
      </font>
    </font>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          pdfdom.com provides users with a 3-day free trial subscription to
          access all premium features, which include but are not limited to
          displaying converted files, fast file processing, downloading
          converted files, and more.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", marginLeft: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          a. During the trial period, users may observe a temporary charge of
          $0.00 to $1.00 in their bank account or transaction report after
          initiating the free trial. This charge serves as a card authorization
          fee, which will be held temporarily and released back to the user upon
          the conclusion of the trial period.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", marginLeft: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          b. If the subscription is not canceled within 3 days, the user will be
          charged the recurring price of $9.99 per week, as indicated on the
          website before the subscription starts, on a recurring basis. The
          recurring subscription payments will be charged via Apple Pay, Google
          Pay, or another authorized service when the 3-day free trial ends.
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>6.&nbsp; Automatic Renewal.</b>
        </font>
      </font>
    </font>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          We automatically renew all paid Memberships before the expiration of
          the term of the paid Membership (where permitted by applicable law).
          When you first subscribe for your paid Membership, you acknowledge
          that when your paid Membership expires it will automatically renew for
          the duration and at the cost indicated to you at your initial
          purchase. Where required by applicable law, the renewal date will be
          specified in a renewal notice provided to you. IF YOU DO NOT WANT YOUR
          PAID MEMBERSHIP TO AUTOMATICALLY RENEW, YOU MUST CANCEL YOUR PAID
          MEMBERSHIP IN ACCORDANCE WITH THE PROCESS SET OUT BELOW IN THESE TERMS
          OF USE.
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>7.&nbsp; Cancellation Of Automatic Renewal.</b>
        </font>
      </font>
    </font>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          If you are a paying Member and you did not cancel your subscription
          you may apply for cancellation at the latest 24 hours before the date
          of renewal of the subscription. If you cancel your paid membership
          less than 24 hours before the expiry of the term of your paid
          Membership, YOUR PAID MEMBERSHIP WILL BE AUTOMATICALLY RENEWED IN
          ACCORDANCE WITH THESE TERMS OF USE (WHERE ALLOWED BY APPLICABLE LAW).
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          a.&nbsp; THE CANCELLATION OF YOUR PAID MEMBERSHIP WILL BE EFFECTIVE
          IMMEDIATELY. NO REFUND IS APPLICABLE UNLESS APPLICABLE LAW OR THESE
          TERMS OF USE ARE OTHERWISE PROVIDED.
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>8.&nbsp; Cancellation Of Your Membership by The Company.</b>
        </font>
      </font>
    </font>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          If we cancel a Member’s Membership for that Member’s breach of these
          Terms of Use, the Member shall not be entitled to a refund for the
          period remaining to elapse until the expiry of the account, without
          prejudice to any damages sought by us as compensation for the loss
          suffered.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", marginLeft: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          a.&nbsp; If we cancel a Member’s Membership in its sole discretion for
          reasons other than that Member’s breach of these Terms of Use, the
          Member may be entitled to a refund for the period remaining to elapse
          until the expiry of the account, if requested by a Member
          additionally.
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>
            9.&nbsp; Your Personal Data Rights &amp; General Data Protection
            Regulation (GDPR), CCPA (California), DPA (United Kingdom), LGPD
            (Brazil), DCIA (Canada), NZPA (New Zealand), APA (Australia), LPDP
            (Turkey).
          </b>
        </font>
      </font>
    </font>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          How we use your data and your data privacy rights are covered under
          our Privacy Policy and are hereby integrated into these Terms of
          Service. If you have questions concerning your data rights and our
          obligations under the GDPR please consult our Privacy Policy. Our
          Privacy Policy covers a variety of information about your data rights
          including but not limited to our obligations and rights as a
          processor, obligations and rights of the controller, subject matter of
          data processing, duration of data processing, nature and purpose of
          data processing, type of personal data collected, categories of data
          subjects collected, and special categories of personal data collected.
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          In case of a personal data removal request, please contact us at
          support@pdfdom.com. Once the data removal request is processed, you
          will receive a confirmation message to your email address.
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>10. &nbsp; Content Rights and Licenses.</b>
        </font>
      </font>
    </font>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          The Services, and all Content other than User Content and all software
          available on the Services or used to create and operate the Services,
          is and remains the property of the Company, and is protected under the
          Copyright Act of 1976, as amended, and other intellectual property
          laws of the United States and any foreign jurisdiction where the
          Services are accessed, and all rights to the Services, such Content,
          and such software are expressly reserved.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", marginLeft: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          a.&nbsp; All trademarks and service marks, whether registered or
          unregistered, as well as product names and company names or logos,
          displayed or mentioned on the Services are the property of their
          respective owners.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", marginLeft: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          b.&nbsp; You must not use such marks without the prior written
          permission of the owner of the marks. Reference to any products,
          services, processes, or other information, by trade name, trademark,
          manufacturer, supplier, or otherwise does not constitute or imply
          endorsement, sponsorship, or recommendation thereof by Company.
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>11.&nbsp; Limited License to Copy Content.</b>
        </font>
      </font>
    </font>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          Company grants to Users a limited, revocable, non-exclusive,
          non-sublicensable license to access the Services and to view, copy,
          and print the portions of the Content available to Users on the
          Services.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", marginLeft: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          a.&nbsp; Such license is subject to these Terms of Service, and
          specifically conditioned upon the following:
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", marginLeft: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          b.&nbsp; Users may only view, copy, and print such portions of the
          Content for their own personal use;
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", marginLeft: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          c.&nbsp; Users may not modify or otherwise make derivative works of
          the Services or Content, or reproduce, distribute, or display the
          Services or any Content (except for page caching) except as expressly
          permitted in these Terms of Service;
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", marginLeft: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          d.&nbsp; Users may not remove or modify any copyright, trademark, or
          other proprietary notices that have been placed in the Content;
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", marginLeft: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          e.&nbsp; Users may not use the Services or Content other than for
          their intended purposes;
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", marginLeft: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          f.&nbsp; Any such unauthorized use may also violate applicable laws,
          including without limitation copyright and trademark laws. Unless
          explicitly stated herein, nothing in these Terms of Service may be
          construed as conferring any license to intellectual property rights,
          whether by estoppels, implication, or otherwise;
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", marginLeft: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          g.&nbsp; Company may revoke this license at any time for any reason or
          no reason, and shall not be liable to any User for any purported
          interference with business or contractual relations, in tort or
          otherwise.
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>12.&nbsp; Intellectual Property.</b>
        </font>
      </font>
    </font>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          Users may not engage in any activity on or through the Services,
          including transmitting or using User Content that infringes or
          otherwise makes unauthorized use of another party’s copyright,
          trademark, or other intellectual property or proprietary rights.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", marginBottom: "0.11in", marginLeft: "0.49in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          a.&nbsp; We will respond to legitimate requests made pursuant to the
          Online Copyright Infringement Liability Limitation Act of the Digital
          Millennium Copyright Act (17 U.S.C. § 512) ("DMCA") and trademark law,
          and we retain the right to remove Content from the Services that
          Company believes in good faith infringes on any third party’s
          intellectual property rights upon notice from the owner of such
          intellectual property, or their agent.
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>13. Third-Party Integration</b>
        </font>
      </font>
    </font>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          . In providing the Service, Company may make available various
          third-party tools to process payments. These are processed
          respectively by Apple Pay (App Store) and Google Pay and are subject
          to their respective terms and conditions. The company is not
          responsible for the performance of any third-party services or the
          security of their service. pdfdom.com does store payment processing
          information and such billing data may be stored by our payment
          processor (Stripe). Please review Stripe’s Terms of Services for any
          questions regarding the storing of your billing information.
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>14.&nbsp; Changes.</b>
        </font>
      </font>
    </font>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          The Company may update and amend these Terms of Service at any time
          and the Company will make the updated Terms of Service available
          through the Services. You understand and agree that you will be deemed
          to have accepted the updated Terms of Service if you use the Services
          after the updated Terms of Service are made available to you. If at
          any point you do not agree to any part of the Terms of Service in
          operation, you should immediately stop using the Services.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", marginLeft: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          a. You acknowledge and agree that the Company may make changes to, or
          stop providing, the Services, or restrict your use of Services, at any
          time without notifying you in advance.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", marginBottom: "0.11in", marginLeft: "0.49in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          b. You acknowledge and agree that the Company can disable or deny you
          access to the Services, without notifying you in advance, for any
          reason or no reason including, without limitation, for any violation
          of these Terms of Service and/or if the Company suspects that you have
          used any aspect of the Services to conduct any fraudulent or illegal
          activity.
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>15.&nbsp; Permitted and Restricted Uses.</b>
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", textIndent: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          a. Website can be used only for lawful purposes by Users of the
          Website. You may not use any meta tags or any various other "unseen
          text" utilizing Company's name or trademarks without the express
          written consent of the Company.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", textIndent: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          b. You agree not to offer or modify any content found on the Website
          consisting of, however not limited to, names of Users and Content, or
          to recreate, display, openly perform, distribute, or otherwise make
          use of the Material, in any way for any public function, in connection
          with services or products that are not those of Company, in another
          way that is likely to trigger confusion among consumers, that
          disparages or challenges Company or its licensors, that dilutes the
          strength of Company's or its licensor's residential property, or that
          otherwise infringes Company's or its licensor's copyright rights.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", textIndent: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          c. Any code that the Company develops to generate or show any Material
          of the pages making up the Site is likewise secured by Company's
          copyright, and you may not copy or adjust such code. Users further
          agree not to decompile, disassemble, or otherwise Reverse Engineer any
          product or service or any part thereof.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", textIndent: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          d. This license does not allow the use of the materials in printed or
          electronic items, non-fungible tokens (NFTs), synthetic personas,
          metaverse avatars, virtual influencers, and other items aimed to be
          resold, in which the Company Content is the main element or one of the
          main elements.
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>DISPUTE PROCESS</b>
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>16.&nbsp; Assumption of Risk.</b>
        </font>
      </font>
    </font>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          You are solely responsible for ensuring that your use of the Services
          complies with applicable law and does not violate the rights of any
          third party, including, without limitation, intellectual property
          rights. You assume all liability for any claims, suits, or grievances
          filed against you, including, but not limited to, all damages related
          to your use of the Services.&nbsp;
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>17.&nbsp; Process</b>
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", textIndent: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          a.&nbsp; Contact Us - You agree to contact us with your complaint
          prior to filing for any arbitration.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", textIndent: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          b.&nbsp; File Complaint - You and Company agree that any dispute must
          be commenced or filed by you or Company within one (1) year of the
          date the dispute arose, otherwise the underlying claim is permanently
          barred (which means that you and Company will no longer have the right
          to assert such claim regarding the dispute).
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", textIndent: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          c.&nbsp; Arbitration - You and Company agree that (a) any arbitration
          will occur in the State of Virginia, (b) arbitration will be conducted
          confidentially by a single arbitrator in accordance with the rules of
          the Judicial Arbitration and Mediation Services, which are hereby
          incorporated by reference, and (c) that the state or federal courts of
          the State of Virginia and the United States, respectively, have
          exclusive jurisdiction over any appeals and the enforcement of an
          arbitration award. You may also litigate a Dispute in the small claims
          court located in the county of your billing address if the Dispute
          meets the requirements to be heard in small claims court.
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>18.&nbsp; California Civil Code Section 1789.3 Compliance.</b>
        </font>
      </font>
    </font>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          Pursuant to California Civil Code Section 1789.3, any questions about
          pricing, complaints, or inquiries about Company must be addressed to
          our agent for notice and sent via certified mail to that agent. For
          our agent’s most current contact information, please send a request to
          support@pdfdom.com
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>19.&nbsp; Communications.</b>
        </font>
      </font>
    </font>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          You consent to receive electronic communications, and you agree that
          all agreements, notices, disclosures, and other communications we
          provide to you electronically, via email, and on the Service, satisfy
          any legal requirement that such communication be in writing.
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          You hereby agree to the use of electronic signatures, contracts,
          orders, and other records, and to electronic delivery of notices,
          policies, and records of transactions initiated or completed by us or
          via the Service.
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>20.&nbsp; Disclaimer of Warranties.</b>
        </font>
      </font>
    </font>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          THE COMPANY DOES NOT WARRANT THAT ANY CONTENT ON THE SERVICE,
          DOCUMENT, OR FEATURE OF THE SERVICE WILL BE ERROR-FREE OR
          UNINTERRUPTED OR THAT ANY DEFECTS WILL BE CORRECTED OR THAT YOUR USE
          OF THE SERVICE WILL PROVIDE SPECIFIC RESULTS. THE CONTENT OF THIS
          SERVICE COULD INCLUDE TECHNICAL INACCURACIES. THE SERVICE AND ITS
          CONTENT ARE DELIVERED ON AN “AS-IS” AND “AS-AVAILABLE” BASIS. ALL
          INFORMATION PROVIDED ON THE SERVICE IS SUBJECT TO CHANGE WITHOUT
          NOTICE. THE COMPANY CANNOT ENSURE THAT ANY FILES, DOCUMENTS OR OTHER
          DATA YOU DOWNLOAD FROM THE SERVICE WILL BE FREE OF VIRUSES OR
          CONTAMINATION OR DESTRUCTIVE FEATURES. THE COMPANY DISCLAIMS ALL
          WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF ACCURACY,
          NON-INFRINGEMENT, MERCHANTABILITY, AND FITNESS FOR A PARTICULAR
          PURPOSE. THE COMPANY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
          INCLUDING ANY WARRANTIES OF ACCURACY, NON-INFRINGEMENT,
          MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. COMPANY
          DISCLAIMS ANY AND ALL LIABILITY FOR THE ACTS, OMISSIONS AND CONDUCT OF
          ANY THIRD PARTIES IN CONNECTION WITH OR RELATED TO YOUR USE OF THE
          SERVICE.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", textIndent: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          a. COMPANY DOES NOT VERIFY FOR ACCURACY ANY INFORMATION PROVIDED TO US
          FROM THIRD PARTIES. YOU ASSUME TOTAL RESPONSIBILITY FOR YOUR USE OF
          THE SERVICE AND ANY LINKED SERVICES. YOU WILL BE SOLELY RESPONSIBLE
          FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM USED
          IN CONNECTION WITH THE SERVICE) OR LOSS OF DATA THAT RESULTS FROM THE
          USE OF THE SERVICE OR THE DOWNLOAD OR USE OF SUCH MATERIALS OR
          CONTENT. SOME JURISDICTIONS MAY PROHIBIT A DISCLAIMER OF WARRANTIES
          AND THEREFORE THIS DISCLAIMER WOULD NOT BE APPLICABLE IN THOSE
          JURISDICTIONS. YOU MAY ALSO HAVE OTHER RIGHTS THAT VARY FROM
          JURISDICTION TO JURISDICTION. YOUR SOLE REMEDY AGAINST THE COMPANY FOR
          DISSATISFACTION, IS TO STOP USING THE SERVICE OR ANY SUCH CONTENT.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", textIndent: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          b. UNLESS SPECIFICALLY WARRANTED ABOVE, COMPANY DOES NOT GRANT ANY
          RIGHT OR MAKE ANY WARRANTY WITH REGARD TO THE USE OF NAMES, PEOPLE,
          TRADEMARKS, TRADE DRESS, LOGOS, REGISTERED, UNREGISTERED OR
          COPYRIGHTED AUDIO, DESIGNS, WORKS DEPICTED OR CONTAINED IN THE
          CONTENT. IN SUCH CASES, YOU ARE SOLELY RESPONSIBLE FOR DETERMINING
          WHETHER RELEASE(S) IS/ARE REQUIRED IN CONNECTION WITH YOUR PROPOSED
          USE OF THE CONTENT, AND YOU ARE SOLELY RESPONSIBLE FOR OBTAINING SUCH
          RELEASE(S). YOU ACKNOWLEDGE THAT SOME JURISDICTIONS PROVIDE LEGAL
          PROTECTION AGAINST A PERSON’S IMAGE, LIKENESS OR PROPERTY BEING USED
          FOR COMMERCIAL PURPOSES WHEN THEY HAVE NOT PROVIDED A RELEASE. YOU ARE
          ALSO SOLELY RESPONSIBLE FOR PAYMENT OF ANY AMOUNTS THAT MAY BE DUE
          UNDER, AND COMPLIANCE WITH ANY OTHER TERMS OF, ANY APPLICABLE
          COLLECTIVE BARGAINING AGREEMENTS AS A RESULT OF YOUR USE OF THE
          LICENSED CONTENT.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", textIndent: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          c.&nbsp; WHILE COMPANY HAS MADE REASONABLE EFFORTS TO CORRECTLY
          CATEGORIZE AND RECORD GRANTS OF EXCLUSIVITY FOR COMMERCIAL USE OF
          LICENSED CONTENT, COMPANY DOES NOT WARRANT OR GUARANTEE EXCLUSIVITY IN
          RESPECT TO EDITORIAL USE OF LICENSED CONTENT. ALL SUCH GRANTS OF
          COMMERCIAL EXCLUSIVITY DO NOT PRECLUDE PRIOR, CONCURRENT, OR
          SUBSEQUENT LICENSES GRANTED FOR EDITORIAL USAGE OF THE SAME CONTENT.
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>21.&nbsp; Limitation of Liability.</b>
        </font>
      </font>
    </font>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          To the fullest extent permitted by applicable law, in no event shall
          the Company parties be liable for any indirect, special, incidental,
          or consequential damages of any kind (including, but not limited to,
          loss of revenue, income or profits, loss of use or data, loss or
          diminution in value of assets, or damages for business interruption)
          arising out of or in any way related to the access to or use of the
          Services or content (including, but not limited to, user content,
          third party content and links to third party Services), or the order,
          receipt or use of any product, or otherwise related to these terms
          (including, but not limited to, any damages caused by or resulting
          from reliance on any information obtained from any Company party, or
          from events beyond the Company parties’ reasonable control, such as
          Service interruptions, deletions of files or emails, errors or
          omissions, defects, bugs, viruses, trojan horses, delays in operation
          or transmission or any failure of performance, whether or not
          resulting from acts of god, communications failure, theft, destruction
          or unauthorized access to the Company parties’ records, programs or
          systems), regardless of the form of action, whether based in contract,
          tort (including, but not limited to, simple negligence, whether
          active, passive or imputed) or any other legal or equitable theory
          (even if the party has been advised of the possibility of such damages
          and regardless of whether such damages were foreseeable). To the
          extent that a Court of Law decides the Company is responsible for any
          damages, users agree that any award for damages shall not exceed the
          total amount of payments paid by the user during the previous one-year
          period to the Company in connection with the services relating to such
          liability.
        </font>
      </font>
    </font>
  </p>
  <p style={{ lineHeight: "150%", marginBottom: "0in" }}>
    <br />
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>22.&nbsp; Indemnification.</b>
        </font>
      </font>
    </font>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          To the fullest extent permitted by applicable law, you agree to
          indemnify, defend and hold harmless the Company, and our respective
          past, present and future employees, officers, directors, contractors,
          consultants, equity holders, suppliers, vendors, service providers,
          parent companies, subsidiaries, affiliates, agents, representatives,
          predecessors, successors and assigns (individually and collectively,
          the “Company Parties”), from and against all actual or alleged Company
          Party or third party claims, damages, awards, judgments, losses,
          liabilities, obligations, penalties, interest, fees, expenses and
          costs of every kind and nature whatsoever, whether known or unknown,
          foreseen or unforeseen, matured or unmatured, or suspected or
          unsuspected, in law or equity, whether in tort, contract or otherwise
          (collectively, “Claims”), including, but not limited to, damages to
          property or personal injury, that are caused by, arise out of or are
          related to:
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", textIndent: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          a. Any use or misuse of the Services, Content or Services by you or
          any third party you authorize to access or use such Services, Content
          or Services;
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", textIndent: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          b. Any User Content you create, post, share or store on or through the
          Services or our pages or feeds on third-party social media platforms;
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", textIndent: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          c. Any Feedback you provide; and,
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", textIndent: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          d. Your violation of these terms, and your violation of the rights of
          another.
        </font>
      </font>
    </font>
  </p>
  <p
    align="justify"
    style={{ lineHeight: "150%", textIndent: "0.49in", marginBottom: "0.11in" }}
  >
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          e. Indemnification of Company by you. You agree to defend, indemnify
          and hold harmless the Company and its parent, subsidiaries,
          affiliates, and content suppliers, and each of their respective
          officers, directors and employees from all damages, liabilities and
          expenses (including reasonable outside legal fees) arising out of or
          in connection with (i) your use of any content outside the scope of
          this agreement; (ii) any breach or alleged breach by you (or anyone
          acting on your behalf) of any of the terms of this or any other
          agreement with Company; and (iii) your failure to obtain any required
          release for your use of content.
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>23.&nbsp; Children.</b>
        </font>
      </font>
    </font>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          This Service is not directed at individuals under the age of eighteen
          (18) in the State of Virginia. The company does not knowingly collect
          personal information from any individual under the age of eighteen
          (18) in the State of Virginia at this Service.
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>24.&nbsp; Entire Agreement.</b>
        </font>
      </font>
    </font>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          These Terms of Service incorporate by reference any notices contained
          in the Services, including within the Privacy Policy and Earning
          Disclaimer, and constitute the entire agreement with respect to access
          to and use of the Services, Services, and Content.
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>25.&nbsp; Severability.</b>
        </font>
      </font>
    </font>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          If any provision of these Terms of Service is deemed unlawful, void,
          or unenforceable by a court of law exercising proper jurisdiction,
          that provision shall be deemed severed from the remaining provisions
          and shall not affect their validity and enforceability.
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>26.&nbsp; No Waiver.</b>
        </font>
      </font>
    </font>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          No waiver of any provision hereof shall be valid unless in writing and
          signed by the parties. Any failure to enforce any right or remedy
          hereunder shall not operate as a waiver of the right to enforce such
          right or remedy in the future or of any other right or remedy.
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>27.&nbsp; No Class Actions.</b>
        </font>
      </font>
    </font>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          You and Company agree that there will be no class arbitration or
          arbitration in which individual attempts to resolve a Dispute as a
          representative of another individual or group of individuals.
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>28.&nbsp; Governing Law and Venue – Tallinn, Estonia.</b>
        </font>
      </font>
    </font>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          Any Dispute between the parties that is not subject to arbitration,
          shall be resolved in the state or federal courts of the State of
          Tallinn and the Estonia, respectively, sitting in the country of Estonia.
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          <b>29.&nbsp; Contact Us:</b>
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          Mediaheap OU
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          Sepapaja tn 6
        </font>
      </font>
    </font>
  </p>
  <p align="justify" style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font color="#000000">
      <font face="Times New Roman, serif">
        <font size={4} style={{ fontSize: "14pt" }}>
          Tallinn, Estonia 15551
        </font>
      </font>
    </font>
  </p>
  <p style={{ lineHeight: "150%", marginBottom: "0.11in" }}>
    <font face="Times New Roman, serif">
      <font size={3} style={{ fontSize: "12pt" }}>
        <br />
      </font>
    </font>
    <br />
    <br />
  </p>
</div>


          <Footer/>

    </div>

  );
}

export default TermsOfService;

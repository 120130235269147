// 1. import `extendTheme` function
import { extendTheme, type ThemeConfig } from '@chakra-ui/react'

// 2. Add your color mode config
const config : ThemeConfig = {
  "breakpoints" : {
  "base": "0px",
  "sm": "320px",
  "md": "768px",
  "lg": "960px",
  "xl": "1200px",
  "2xl": "1536px",
},
  "colors": {
    "gray": {
      "50": "#F2F2F2",
      "100": "#DBDBDB",
      "200": "#C4C4C4",
      "300": "#ADADAD",
      "400": "#969696",
      "500": "#808080",
      "600": "#666666",
      "700": "#4D4D4D",
      "800": "#333333",
      "900": "#1A1A1A"
    },
    "red": {
      "50": "#FCE8E8",
      "100": "#F7C0C0",
      "200": "#F29797",
      "300": "#ED6E6E",
      "400": "#E84545",
      "500": "#E31C1C",
      "600": "#B51717",
      "700": "#881111",
      "800": "#5B0B0B",
      "900": "#2D0606"
    },
    "orange": {
      "50": "#F2F2F2",
      "100": "#DCDCDB",
      "200": "#C5C5C4",
      "300": "#AEAEAC",
      "400": "#989895",
      "500": "#81817E",
      "600": "#676765",
      "700": "#4D4D4C",
      "800": "#343432",
      "900": "#1A1A19"
    },
    "yellow": {
      "50": "#F9F4EC",
      "100": "#EDE1C9",
      "200": "#E2CEA7",
      "300": "#D6BB84",
      "400": "#CBA862",
      "500": "#C0953F",
      "600": "#997733",
      "700": "#735926",
      "800": "#4D3C19",
      "900": "#261E0D"
    },
    "green": {
      "50": "#E5FFF2",
      "100": "#B8FFDA",
      "200": "#8AFFC2",
      "300": "#5CFFAB",
      "400": "#2EFF93",
      "500": "#00FF7B",
      "600": "#00CC62",
      "700": "#00994A",
      "800": "#006631",
      "900": "#003319"
    },
    "teal": {
      "50": "#FFE5E5",
      "100": "#FFB8B8",
      "200": "#FF8A8A",
      "300": "#FF5C5C",
      "400": "#FF2E2E",
      "500": "#FF0000",
      "600": "#CC0000",
      "700": "#990000",
      "800": "#660000",
      "900": "#330000"
    },
    "cyan": {
      "50": "#E5FBFF",
      "100": "#B8F4FF",
      "200": "#8AECFF",
      "300": "#5CE5FF",
      "400": "#2EDDFF",
      "500": "#00D6FF",
      "600": "#00ABCC",
      "700": "#008099",
      "800": "#005666",
      "900": "#002B33"
    },
    "pink": {
      "50": "#FFE5EC",
      "100": "#FFB8C9",
      "200": "#FF8AA7",
      "300": "#FF5C85",
      "400": "#FF2E62",
      "500": "#FF0040",
      "600": "#CC0033",
      "700": "#990026",
      "800": "#66001A",
      "900": "#33000D"
    },
    "purple": {
      "50": "#EDE6FE",
      "100": "#CDBAFD",
      "200": "#AD8DFC",
      "300": "#8E60FB",
      "400": "#6E34F9",
      "500": "#4E07F8",
      "600": "#3E05C7",
      "700": "#2F0495",
      "800": "#1F0363",
      "900": "#100132"
    },
    "blue": {
      "50": "#F2F2F2",
      "100": "#DBDBDB",
      "200": "#C4C4C4",
      "300": "#ADADAD",
      "400": "#969696",
      "500": "#808080",
      "600": "#666666",
      "700": "#4D4D4D",
      "800": "#333333",
      "900": "#1A1A1A"
    }
  }
}

// 3. extend the theme
var theme = extendTheme({ config })
 theme = extendTheme({
  styles: {
    global: {
      "@media (max-resolution: 400px)": {
        body: {
          "-webkit-font-smoothing": "antialiased"
        }
      }
    }
  }
});

export default theme
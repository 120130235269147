import logo from '../logo.svg';
import Header from '../parts/header';
import Footer from '../parts/footer';
import {useDropzone} from 'react-dropzone'
import styled from 'styled-components';
import { Heading } from '@chakra-ui/react'
import {Text, Center, Square, Tabs, TabList, TabPanel, TabPanels, Tab, Circle, SimpleGrid, Box, Container, Image, Checkbox,Spinner, Grid, GridItem,FormControl, FormLabel, Input, Select, Button, ButtonGroup, InputRightAddon, InputGroup, Modal,ModalOverlay, ModalContent, ModalHeader,ModalCloseButton,ModalBody, ModalFooter } from '@chakra-ui/react'
import React, {useCallback} from 'react'
import {useMemo, useState, useEffect, useRef} from 'react';
import './dashboard.css'; // Tell webpack that Button.js uses these styles
import { Wrap, WrapItem, useColorModeValue } from '@chakra-ui/react'
import { Tooltip } from '@chakra-ui/react'
import { Switch } from '@chakra-ui/react'
import { Flex, Spacer } from '@chakra-ui/react'
import { saveAs } from 'save-as'
import {Helmet} from "react-helmet";
import { useToast } from '@chakra-ui/react'

import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import Cookies from 'js-cookie';
import ReactGA from "react-ga4";
import { CheckIcon, AttachmentIcon } from '@chakra-ui/icons'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { useDimensions } from '@chakra-ui/react'

import PaymentBox from './payment_box'

import { CardElement, useElements, useStripe , PaymentElement, PaymentRequestButtonElement} from "@stripe/react-stripe-js";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
// const stripePromise = loadStripe("pk_test_xTxkUI4xEvwcy5g79VXZa83G00ozIR3PBp"); // starts with pk_
 const stripePromise = loadStripe("pk_live_xVwah1y9DU0urQpzPPGvLbiM00Pdsq1Ri4"); // starts with pk_



function Dashboard() {


  const elementRef = useRef()
  const dimensions = useDimensions(elementRef)
	
	  const toast = useToast();

	 const [isLoggedin, setIsLoggedin] = useState(false);
   const [profileData, setProfileData] = useState({});
   const [currentUserId, setCurrentUserId] = useState("");
  const [ isOpen, setOpen ] = useState(false);
  const [ downloadLinkEnabled, setDownloadLinkEnabled ] = useState(false);
  const [ defaultTabIndex, setDefaultTabIndex ] = useState(1);


	const [paths, setPaths] = useState([]);
	const [path_retrigger, setPathretriger] = useState(false);

	const [convertedBlobs, setConvertedBlobs] = useState([]);
	const [convertedPaths, setConvertedPaths] = useState([]);
	const [outputWidth, setOutputWidth] = useState("1000");
	const [outputHeight, setOutputHeight] = useState("1000");
	const [resizeImages, setResizeImages] = useState(false);
	const [exportFormat, setExportFormat] = useState("PNG");
	const [compressImages, setCompressImages] = useState(false);
	const [AlertisOpen, setAlertisopen] = useState(false);

	const AlertonClose = () =>
	{
		setAlertisopen(false);
		setOpen(false);
		setPaths([]);

	}

	const AlertonTrial = () =>
	{
		setAlertisopen(false);


	}

	

	const options = {
  currency: 'usd',
  amount: 0.00,
  setup_future_usage: 'off_session',
  mode: 'subscription',
  paymentMethodCreation:'manual',


};

	const pdf_icon = "imgs/pdf.svg";
	const docx_icon = "imgs/docx.png";


	useEffect(() => {
   
     if(Cookies.get("id")) {
      setCurrentUserId(Cookies.get("id"));
  console.log(Cookies.get("id"));
    getUserProfileData(Cookies.get("id"))

  }

  }, []);






 const getUserProfileData = (id) => {

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({  id:id})

    };
    console.log(requestOptions);
    fetch('https://api.pdfdom.com/api/user/profile', requestOptions)
        .then(function(response) {
    return response.json();
  }).then(function(data) {
              console.log(data);  


 
    if(data.id)
    {
     
        setProfileData(data);


    }


  });



    }



	const baseStyle = {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '20px',
		borderWidth: 2,
		borderRadius: 10,
		borderColor: '#eeeeee',
		borderStyle: 'dashed',
		color: '#bdbdbd',
		outline: 'none',
		minHeight:'100px',
		verticalAlign:'middle',
		transition: 'border .24s ease-in-out'
	};

	const focusedStyle = {
		borderColor: '#2196f3'
	};

	const acceptStyle = {
		borderColor: '#00e676'
	};

	const rejectStyle = {
		borderColor: '#ff1744'
	};
	const getColor = (props) => {
		if (props.isDragAccept) {
			return '#00e676';
		}
		if (props.isDragReject) {
			return '#ff1744';
		}
		if (props.isFocused) {
			return '#2196f3';
		}
		return '#eeeeee';
	}

	useEffect(function(){
		console.log(paths);

		
  processPaths();


	}, [paths.length, path_retrigger])

const processPaths = async () => {
  for (let i = 0; i < paths.length; i++) {
    const path = paths[i];
    if (!path.converted_image && !path.processing) {
      try {
        await removeBackgroundApi(path, i);
      } catch (error) {
        console.error('Error processing item at index', i, error);
        // Handle the error accordingly
      }
    }
  }
};

	const removeBackgroundApi = async (path, index) => {

			setDefaultTabIndex(2);
			setPaths(paths.map((item,i)=>{
				if(index === i){
					return {...item, processing: true};
				}
				return item;
			}))
		console.log("called");
		const form = new FormData();
		form.append("pdf", path.file, path.file.name);
		if(profileData?.api_key)
		{
					form.append("api", profileData?.api_key);

		}
		if(Cookies.get("trial_created_no_account"))
		{
		  form.append("trial_created_no_account", true);

		}

		const options = {
			method: 'POST',
			headers: {}
		};

		options.body = form;
    const response = await fetch(`https://api.pdfdom.com/api/convert_pdf_to_word?format=${exportFormat}&whiteBG=true&outputSize=${outputWidth}x${outputHeight}`, options);

		fetch('https://api.pdfdom.com/api/convert_pdf_to_word?format='+exportFormat+'&whiteBG=true&outputSize='+outputWidth+'x'+outputHeight+'', options)
		.then( (response) => 
			{ const contentType = response.headers.get("content-type");
			if(contentType.indexOf("application/json") !== -1)
			{
				return response.json()
			}
			else
			{

			 return response.blob()	
			}


				
			})
		.then( (response) => {

			console.log(response)
			if(response.limit_reached)
			{

				setPaths(paths.map((item,i)=>{
				if(index === i){
					return {...item, processing: false};
				}
				return item;
			}))
					setOpen(true);


                              ReactGA.event({
  category: "conversion",
  action: "limit_reached",
  label: profileData?.id ? profileData?.id  : "guest" , // optional
  nonInteraction: true, // optional, true/false
});


			}
			else
			{

                              ReactGA.event({
  category: "conversion",
  action: "file_conversion_sucessfull",
  label: profileData?.id ? profileData?.id  : "guest", // optional
  nonInteraction: true, // optional, true/false
});

					setPaths(paths.map((item,i)=>{
				if(index === i){
					return {...item, converted_image: URL.createObjectURL(response), processing:false};
				}
				return item;
			}))
			}

		



		}


		)

		.catch(err => console.error(err));


	}


	const setImageChecked = async (index, checked) => {

			setPaths(paths.map((item,i)=>{
				if(index === i){
					return {...item, checked: checked};
				}
				return item;
			}))




	}

		const downloadSelectedImages = async () => {

				 const zip = new JSZip();
    let count = 0;
    const zipFilename = "converted.zip";
    paths.forEach(async function (path){

    	if(path.checked)
    	{


      var extension = path.file.name.substr(path.file.name.lastIndexOf('.') + 1);
      var final_extension = exportFormat == "PNG" ? ".png" : "jpg";

      final_extension = ".docx";
      const filename = path.file.name.replace(extension, "")+"."+final_extension;

      try {
        const file = await JSZipUtils.getBinaryContent(path.converted_image)
        zip.file(filename, file, { binary: true});
        count++;
        if(count === paths.length) {
          zip.generateAsync({type:'blob'}).then(function(content) {
            saveAs(content, zipFilename);
          });
        }
      } catch (err) {
        console.log(err);
      }

  }
    });




	}




		const selectAllImages = async (checked) => {

			setPaths(paths.map((item,i)=>{
				
					return {...item, checked: checked};
				
				return item;
			}))




	}
	const downloadFile = (link) => {

                              ReactGA.event({
  category: "conversion",
  action: "file_downloaded",
  label: "File Downloaded ", // optional
  nonInteraction: true, // optional, true/false
});
    window.location.href = link;

  }
  
	function StyledDropzone(props) {
		const {
			isFocused,
			isDragAccept,
			isDragReject
		} = useDropzone({accept: {'text/html': []}});

		const onDrop = useCallback((acceptedFiles) => {

			var new_files  = [];

			acceptedFiles.forEach((file) => {
				console.log(file);


				var original_image = URL.createObjectURL(file);
				console.log(original_image);

				new_files.push({original_image:original_image, converted_image:"", file:file, checked:false, processing:false});

           // setPaths([...paths, {original_image:original_image, converted_image:"", file:file} ]);





       })

			setPaths([...paths, ...new_files ]);


		}, [])
		const {getRootProps, getInputProps} = useDropzone({onDrop})

		const style = useMemo(() => ({
			...baseStyle,
			...(isFocused ? focusedStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {})
		}), [
		isFocused,
		isDragAccept,
		isDragReject
		]);

		return (
  		<Container maxW='md' bg='white.600' color='black' style={{marginBottom:'50px'}}>
			<div className="container">
			<Box {...getRootProps( {
	style,
  onClick: event =>{ if(downloadLinkEnabled) {event.stopPropagation()} }
})} bg={useColorModeValue('#e8eef2', 'white')}  >
			<input {...getInputProps()} />
			 <Button variant="solid" leftIcon={<AttachmentIcon />} size="md" colorScheme="red" style={downloadLinkEnabled ? {display:'None'}: {}}>
      Upload your PDF
    </Button>
			<p>or drag n drop the PDF file/files </p>


				<Wrap columns={4} spacing={10}>

		{paths.map(function(data, index) {
			if(data.converted_image)
			{
				setDownloadLinkEnabled(true);
			}
			return (
			<WrapItem>

			<Checkbox isChecked={data.checked} onChange={(e)=> setImageChecked(index, e.target.checked) }>
			<Image key={data.original_image} src={data.converted_image? docx_icon: pdf_icon } boxSize='100px'
			objectFit='cover' loading='eager' style={data.converted_image ? {}: {opacity:0.4}} onClick={()=>downloadFile(data.converted_image)} /> 
			<Button variant="solid" size="md" colorScheme="red" style={downloadLinkEnabled ? {}: {display:'None'}} onClick={()=>downloadFile(data.converted_image)}>Download</Button>

			<Center className="spinner_wrapper" style={data.converted_image ? {display:'None'}: {position:"absolute", top:'50%', left:'50%'}}>
			<Spinner style={data.converted_image ? {display:'None'}: {position:"absolute", top:'50%', left:'50%'}}/>
			</Center>
			

			</Checkbox>

			</WrapItem>

			)
		})}


		</Wrap>
			</Box>


			</div>
			</Container>
			);
		}


		return (

    <div class="home">
		 <Helmet>
           <meta charSet="utf-8" />
            <title>PDF to DOCX (Word) Conversion  - Free</title>
              <meta name="description" content="Discover a free, user-friendly PDF to DOCX conversion tool! Effortlessly transform PDFs into editable DOCX files without cost. Try our no-charge converter for seamless document transitions." />
            <link rel="canonical" href="http://pdfdom.com/" />
            </Helmet>
		<Header/>

		<Container>
		<Center>
		<img src={'imgs/pdf-to-doc.svg'} alt='mySvgImage' />
		</Center>
		</Container>
		<Center  h='100px' color='black'>

			<Text>Convert Any <strong>PDF</strong> document to <strong>Word (DOCX)</strong> easily !</Text>
		
		</Center>



		<Grid templateColumns='repeat(5, 2fr)' autoColumns={true} gap={6} style={{marginBottom:'10px'}}>

		<GridItem w='100%'  >
		
		</GridItem>
		<GridItem w='100%'  >
	
		</GridItem>


		<GridItem w='100%'  >
		
		</GridItem>

		<GridItem w='100%'  >
		
		</GridItem>


		<GridItem w='100%'  >
		
		</GridItem>




		</Grid>


	


<Flex minWidth='max-content' alignItems='center' gap='2'  style={{marginBottom:'10px', display:'None'}}>
  <Box p='2'>
   <Button onClick={()=>selectAllImages(true)}>Select All</Button>

  </Box>
  <Box p='2'>
        <Button onClick={()=>selectAllImages(false)}>UnSelect All</Button>
  </Box>

  <ButtonGroup gap='2'>
    <Button colorScheme='teal' onClick={()=>downloadSelectedImages()}>Download</Button>
    <Button colorScheme='teal'>Remove</Button>
  </ButtonGroup>
    <Spacer />

</Flex>

		<StyledDropzone />

		<Modal isOpen={isOpen} onClose={()=> { if(Cookies.get("trial_created_no_account")) { setOpen(false) } else {setAlertisopen(true)} } }>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Free Trial Upgrade</ModalHeader>
          <ModalCloseButton />
           <Elements stripe={stripePromise} options={options}>
						 <PaymentBox onPaymentSuccess={()=>{setOpen(false); setPathretriger(true); }} onPaymentFailed={()=> {setPaths([]);} } />
   					 </Elements>
          
        </ModalContent>
      </Modal>

       <AlertDialog
        isOpen={AlertisOpen}
        onClose={AlertonClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Cancel Conversion
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can start a free trial for 3 days.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={AlertonClose}>
                Cancel Conversion
              </Button>
              <Button colorScheme='blue' onClick={AlertonTrial} ml={3}>
                Start Free Trial
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      	


		<Footer/>

		</div>

		);
	}

	export default Dashboard;

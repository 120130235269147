import logo from '../logo.svg';
import Header from '../parts/header';
import Footer from '../parts/footer';
import TestimonialWithSpeechBubbles from './home-parts/testimonials';
import Pricing from './home-parts/pricing';
import LoginForm from './login-parts/loginform';
import {Helmet} from "react-helmet";

function Login() {
  return (

    <div className="home">
     <Helmet>
           <meta charSet="utf-8" />
            <title>Login -  PDFDom</title>
             <meta name="description" content="Join in for convenient access to our bulk PDF conversion services. We're here to ensure your satisfaction and a seamless bulk conversion experience!" />

            <link rel="canonical" href="https://www.pdfdom.com/sign-in" />
            </Helmet>
      <Header/>

   		<LoginForm/>

          <Footer/>

    </div>

  );
}

export default Login;
